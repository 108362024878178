import {observer} from "mobx-react";
import {Page} from "../../styles/page";
import AppStore from "../../app.store";
import ClientsDebtsStore from "./clients.debts.store";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import {useEffect, useState} from "react";
import {Headline} from "../../styles/headline";
import {primaryColor} from "../../utils/variables";
import {Filters} from "../../styles/filters";
import FilterView from "../../components/filters/filters.view";
import {FilterButton} from "../../styles/button";
import {TableHeader} from "../../styles/table-header";
import TablePaginationView from "../../utils/table.pagination.view";
import TableDesktopView from "../../components/table/render.table.desktop";
import TableMobileView from "../../components/table/render.table.mobile";
import BusinessUnitsStore from "../business_units/business_units.store";
import UsersStore from "../users/users.store";
import ClientsStore from "../clients/clients.store";
import ModalView from "../../components/modal/modal.view";
import {ClientsDebtsModel} from "./clients.debts.model";
import AccountModalView from "../../components/modal/account.modal.view";
import ClientsDebtsOperationsView from "./clients.debts.operations.view";
import ClientsDebtsPaymentModalView from "../clients/clients.debts.payment.modal/clients.debts.payment.modal.view";

const ClientsDebtsView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        clientDebts,
        clientDebtsFilters,
        setClientDebtsFilters,
        clientDebtsLoading,
        initClientDebtsPage,
        destroyPaymentForm
    } = ClientsDebtsStore;
    const {businessUnits, setBusinessUnitFilter, businessUnitsLoading} = BusinessUnitsStore;
    const {users, setUsersFilter, usersLoading} = UsersStore;
    const {clients, setClientsFilter, clientsLoading} = ClientsStore;
    const {destroyDictionary, dictionaryListReq, getPageTitle, dictionaryList} = DictionaryStore;
    const [filterModal, setFilterModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ClientsDebtsModel | null>(null);
    const [operationsModal, setOperationsModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    )

    const usersOptions = users?.map(
        ({id, full_name}) => ({label: full_name, value: id})
    )

    const clientsOptions = clients?.map(
        ({id, full_name}) => ({label: full_name, value: id})
    )

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        void dictionaryListReq('CLIENT_DEBTS', 'TABLE HEADERS', controller.signal);
        void initClientDebtsPage(controller.signal);

        return () => {
            controller.abort();
        };
    }, [destroyDictionary, dictionaryListReq, initClientDebtsPage]);

    const handleFilterChange = (e: any, objectKey: string, index?: number, antSelect?: boolean) => {
        if (objectKey === 'page' && index !== undefined) {
            setClientDebtsFilters({ page: index + 1 });
            return;
        }

        if (!antSelect) {
            const {
                target: { options, selectedIndex },
            } = e;

            setClientDebtsFilters({ [objectKey]: options[selectedIndex].value });
        } else {
            setClientDebtsFilters({ [objectKey]: e });
        }
    };



    const cancelSearch = (e: any) => {
        if (e.target.value === '' && clientDebtsFilters.search !== '') {
            setClientDebtsFilters({ search: '' });
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setClientDebtsFilters({ search: e.target.value });
        }
    };

    const setDates = (e: any) => {
        if (e !== null) {
            setClientDebtsFilters({date_to: e[1].format('YYYY-MM-DD')});
            setClientDebtsFilters({date_from: e[0].format('YYYY-MM-DD')});
        } else {
            setClientDebtsFilters({date_from: ''});
            setClientDebtsFilters({date_to: ''});
        }
    };

    const prevPage = () => {
        if (clientDebtsFilters.page! > 1) {
            setClientDebtsFilters({page: clientDebtsFilters.page! - 1});
        }
    };

    const nextPage = () => {
        if (clientDebtsFilters.page! < clientDebtsFilters.pages!) {
            setClientDebtsFilters({page: clientDebtsFilters.page! + 1});
        }
    };

/*    const statusOption = [
        {
            label: 'Не оплачен',
            value: 1,
        },
        {
            label: 'Частично оплачен',
            value: 2,
        },
        {
            label: 'Оплачен',
            value: 3,
        },
    ]*/

    const pageLimits = [25, 50, 100, 250, 500];

    const filterInputs = [
        {
            type: 'range_picker',
        },
        {
            fieldName: 'search',
            placeholder: 'Поиск',
            type: 'search',
        },
        {
            fieldName: 'business_unit_id',
            placeholder: 'Все подразделения',
            type: 'select',
            options: businessUnitsOptions,
            hasSearch: true,
            loading: businessUnitsLoading,
            onFocus: () => setBusinessUnitFilter({page: 1, page_limit: 1000000, return_balance: false}),
        },
        {
            fieldName: 'user_id',
            placeholder: 'Все сотрудники',
            type: 'select',
            options: usersOptions,
            hasSearch: true,
            loading: usersLoading,
            onFocus: () => setUsersFilter({page: 1, page_limit: 100000}),
        },
        {
            fieldName: 'client_id',
            placeholder: 'Все клиенты',
            type: 'select',
            options: clientsOptions,
            hasSearch: true,
            loading: clientsLoading,
            onFocus: () => setClientsFilter({page: 1, page_limit: 1000000}),
        },
        /*{
            fieldName: 'status',
            placeholder: 'Все долги',
            type: 'select',
            options: statusOption,
        },*/
    ];

    const handleClickOperations = (debt: ClientsDebtsModel) => {
        setInitialValue(debt);
        setOperationsModal(true);
    }

    const handleCloseOperationsModal = () => {
        const controller = new AbortController();
        void dictionaryListReq('CLIENT_DEBTS', 'TABLE HEADERS', controller.signal);
        setInitialValue(null);
        setOperationsModal(false);
    }

    const handlePaymentClick = (debt: ClientsDebtsModel) => {
        setInitialValue(debt);
        setPaymentModal(true);
    }

    const handleClosePaymentModal = () => {
        setPaymentModal(false);
        setInitialValue(null);
        destroyPaymentForm();
    }

    const handlePaymentConditions = (debt: ClientsDebtsModel) => {
        return debt.status !== 3
    }

    const contextMenuOptions = [
        {
            name: 'Провести оплату',
            onClick: (debt: ClientsDebtsModel) => handlePaymentClick(debt),
            condition: (debt: ClientsDebtsModel) => handlePaymentConditions(debt),
        },
        {
            name: 'Показать историю',
            onClick: (debt: ClientsDebtsModel) => handleClickOperations(debt),
            condition: () => true,
        }
    ];

    return (
        <Page isNavOpen={isNavOpen} fadeIn between={20} vertical>
            <Headline color={primaryColor}>{getPageTitle('CLIENT_DEBTS')}</Headline>
            <TableHeader>
                <Filters hAlign="space-between" wrapped between={10}>
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={false}
                        filters={clientDebtsFilters}
                        handleFilterChange={handleFilterChange}
                        setDates={setDates}
                        cancelSearch={cancelSearch}
                        handleKeyDown={handleKeyDown}
                    />
                </Filters>
                <FilterButton
                    id={'client-debts-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
            </TableHeader>
            <TableDesktopView data={clientDebts} headers={dictionaryList} loading={clientDebtsLoading} hasContext
                              contextOptions={contextMenuOptions}/>
            <TableMobileView data={clientDebts} headers={dictionaryList} loading={clientDebtsLoading} hasContext
                             contextOptions={contextMenuOptions}/>
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={clientDebtsFilters.pages}
                currentPage={clientDebtsFilters.page!}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {filterModal && (
                <ModalView
                    title={'Фильтры'}
                    onClose={() => setFilterModal(false)}
                >
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={true}
                        filters={clientDebtsFilters}
                        handleFilterChange={handleFilterChange}
                        setDates={setDates}
                        cancelSearch={cancelSearch}
                        handleKeyDown={handleKeyDown}
                    />
                </ModalView>
            )}
            {operationsModal && (
                <AccountModalView
                    title={`История долга ${initialValue?.client_name}`}
                    onClose={handleCloseOperationsModal}
                    hasContext
                    fullScreen
                >
                    <ClientsDebtsOperationsView initialValue={initialValue}/>
                </AccountModalView>
            )}
        </Page>
    );
});

export default ClientsDebtsView;