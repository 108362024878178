import {Headline} from '../../styles/headline';
import {primaryColor} from '../../utils/variables';
import {observer} from 'mobx-react';
import {
    ContextMenu,
    ContextMenuItem,
    Table,
    TableBody,
    TableContainer,
    TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import AppStore from '../../app.store';
import {Page} from '../../styles/page';
import ClientsStore from './clients.store';
import {useEffect, useState} from 'react';
import {
    AddButton,
    SearchLayout,
    Search,
    TableHeader,
} from '../../styles/table-header';

import Modal from '../../components/modal/modal.view';
import AccountModal from '../../components/modal/account.modal.view';
import UsersCreateEditView from './clients.modal/clients.create.edit.view';
import {Layout} from '../../styles/layout';
import {Image} from '../../styles/image';
import SearchIcon from '../../assets/icons/search.svg';
import ContextMenuIcon from '../../assets/icons/context-menu.svg';
import {ClientsModel} from './clients.model';
import ConfirmDelete, {
    DeleteItem,
} from '../../components/confirm/confirm.delete';
import {Filters} from '../../styles/filters';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import AccountView from '../accounts/account.view';
import AccountStore from '../accounts/account.store';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import {Button, FilterButton} from '../../styles/button';
import TablePaginationView from '../../utils/table.pagination.view';
import ClientsTableMobileView from './clients.mobile.view';
import ClientsContactsView from '../client.contacts/clients.contacts.view';
import AccountModalView from '../../components/modal/account.modal.view';
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import CardsView from "../cards/cards.model.view";
import ClientDocumentsView from "../client.documents/client.documents.view";
import ClientsDebtsPaymentModalView from "./clients.debts.payment.modal/clients.debts.payment.modal.view";

const ClientsView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        clients,
        clientsFilter,
        clientsPageInit,
        setClientsFilter,
        deleteClient,
        clientsLoading,
        clearFilter,
    } = ClientsStore;
    const [modal, setModal] = useState(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
        isModalOpen: false,
    });
    const [initialValue, setInitialValue] = useState<ClientsModel | null>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [accountModal, setAccountModal] = useState(false);
    const {accountsReq} = AccountStore;
    const [filterModal, setFilterModal] = useState(false);
    const [clientContactsModal, setClientsContactsModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;
    const [cardModal, setCardModal] = useState(false);
    const [documentsModal, setDocumentsModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        void dictionaryListReq('CLIENTS', 'TABLE HEADERS', controller.signal);
        void clientsPageInit(false, controller.signal);

        return () => {
            controller.abort();
        };
    }, [destroyDictionary, dictionaryListReq, clientsPageInit]);

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setClientsFilter({page: index + 1});
            return;
        }
        setClientsFilter({[objectKey]: options[selectedIndex].value});
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && clientsFilter.search !== '') {
            setClientsFilter({search: ''});
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setClientsFilter({search: e.target.value});
        }
    };

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const handleEditClick = (client: ClientsModel) => {
        setModal(true);
        setInitialValue(client);
    };

    const handleAccountClick = (client: ClientsModel) => {
        setInitialValue(client);
        void accountsReq(false, 'client', client.id.toString());
        setAccountModal(true);
    };
    const handleClientContactsClick = (client: ClientsModel) => {
        setInitialValue(client);
        setClientsContactsModal(true);
    };

    const handleDelete = async () => {
        await deleteClient(confirmDeleteModal.id!);
        setConfirmDeleteModal({isModalOpen: false});
    };

    const prevPage = () => {
        if (clientsFilter.page > 1) {
            setClientsFilter({page: clientsFilter.page - 1});
        }
    };

    const nextPage = () => {
        if (clientsFilter.page < clientsFilter.pages) {
            setClientsFilter({page: clientsFilter.page + 1});
        }
    };

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    };

    const handleConfirmFilter = () => {
        setFilterModal(false);
    };

    const handleCloseContactModal = () => {
        void dictionaryListReq('CLIENTS', 'TABLE HEADERS');
        setInitialValue(null);
        setClientsContactsModal(false);
    }

    const handleCardsClick = (client: ClientsModel) => {
        setInitialValue(client);
        setCardModal(true);
    }

    const handleCloseCardsModal = () => {
        setInitialValue(null);
        setCardModal(false);
    }

    const handleDocumentsClick = (client: ClientsModel) => {
        setInitialValue(client);
        setDocumentsModal(true);
    }

    const handleCloseDocumentsModal = () => {
        setInitialValue(null);
        setDocumentsModal(false);
    }

    const pageLimits = [5, 10, 25, 100, 500];

    const handleClickPayment = (client: ClientsModel) => {
        setInitialValue(client);
        setPaymentModal(true)
    }

    const handleClosePaymentModal = () => {
        setInitialValue(null);
        setPaymentModal(false);
    }

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <>
                <Headline color={primaryColor}>{getPageTitle('CLIENTS')}</Headline>
                <TableHeader>
                    <Filters hAlign="space-between" wrapped between={10}>
                        <Layout between={10} wrapped vAlign="center">
                            <SearchLayout>
                                <Search
                                    type="search"
                                    id="search"
                                    placeholder="Поиск клиента"
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={(e) => cancelSearch(e)}
                                ></Search>
                                <Image src={SearchIcon} height="16px"/>
                            </SearchLayout>
                        </Layout>
                    </Filters>
                    <FilterButton
                        id={'clients-filter-button'}
                        onClick={() => setFilterModal(true)}
                    >
                        Фильтры
                    </FilterButton>
                    <AddButton type="button" id="add-user" onClick={() => setModal(true)}>
                        Создать
                    </AddButton>
                </TableHeader>
                <TableContainer isMacOS={AppStore.isMacOS}>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>{"ID"}</THeadCell>
                                <THeadCell>{getTableHeaders('full_name')}</THeadCell>
                                <THeadCell>{getTableHeaders('pseudonym')}</THeadCell>
                                <THeadCell>{getTableHeaders('phone')}</THeadCell>
                                <THeadCell>{getTableHeaders('inn')}</THeadCell>
                                <THeadCell>{getTableHeaders('type_id')}</THeadCell>
                                <THeadCell>{getTableHeaders('address')}</THeadCell>
                                <THeadCell>{getTableHeaders('email')}</THeadCell>
                                <THeadCell>Баланс</THeadCell>
                                <THeadCell></THeadCell>
                            </TRow>
                        </TableHead>
                        {!clientsLoading ? (
                            <TableBody>
                                {clients!.length ? (
                                    clients?.map((client, index) => (
                                        <TRow key={index} menu>
                                            <TCell>{client.client_owner_id}</TCell>
                                            <TCell>{client.full_name}</TCell>
                                            <TCell>{client.pseudonym}</TCell>
                                            <TCell>{client.phone}</TCell>
                                            <TCell>{client.inn}</TCell>
                                            <TCell>{client.client_types}</TCell>
                                            <TCell>{client.current_address}</TCell>
                                            <TCell>{client.email}</TCell>
                                            <TCell>{client.balance}</TCell>
                                            <TCell relative>
                                                <Image
                                                    src={ContextMenuIcon}
                                                    height="18px"
                                                    onClick={handleContext}
                                                />

                                                <ContextMenu anchorEl={anchorEl}>
                                                    <ContextMenuItem
                                                        onClick={() => handleClickPayment(client)}
                                                    >
                                                        Провести оплату
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() => handleClientContactsClick(client)}
                                                    >
                                                        Контакты
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() => handleDocumentsClick(client)}
                                                    >
                                                        Документы
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() => handleAccountClick(client)}
                                                    >
                                                        Счета
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() => handleCardsClick(client)}
                                                    >
                                                        Карты
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() => handleEditClick(client)}
                                                    >
                                                        Изменить
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() =>
                                                            setConfirmDeleteModal({
                                                                isModalOpen: true,
                                                                id: client.id,
                                                                name: client.full_name,
                                                            })
                                                        }
                                                    >
                                                        Удалить
                                                    </ContextMenuItem>
                                                </ContextMenu>
                                            </TCell>
                                        </TRow>
                                    ))
                                ) : (
                                    <NoResultTitle colspan={10}/>
                                )}
                            </TableBody>
                        ) : (
                            <TableSkeleton columns={10}/>
                        )}
                    </Table>
                </TableContainer>
                <TableContainerMobile>
                    <ClientsTableMobileView
                        clients={clients}
                        loading={clientsLoading}
                        handleClientContactsClick={handleClientContactsClick}
                        handleAccountClick={handleAccountClick}
                        handleEditClick={handleEditClick}
                        setConfirmDeleteModal={setConfirmDeleteModal}
                        tableHeaders={getTableHeaders}
                        handleCardsClick={handleCardsClick}
                        handleDocsClick={handleDocumentsClick}

                    />
                </TableContainerMobile>
                <TablePaginationView
                    prevPage={prevPage}
                    nextPage={nextPage}
                    pages={clientsFilter.pages}
                    currentPage={clientsFilter.page}
                    pageLimits={pageLimits}
                    handleFilterChange={handleFilterChange}
                />
            </>
            {filterModal && (
                <Modal title={'Фильтры'} onClose={() => setFilterModal(false)}>
                    <Layout vertical between={10}>
                        <SearchLayout>
                            <Search
                                type="search"
                                id="search"
                                placeholder="Поиск по ФИО и номеру телефона"
                                onKeyDown={(e) => handleKeyDown(e)}
                                onChange={(e) => cancelSearch(e)}
                            ></Search>
                            <Image src={SearchIcon} height="16px"/>
                        </SearchLayout>
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={handleConfirmFilter}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>
                </Modal>
            )}
            {clientContactsModal && (
                <AccountModalView
                    hasContext={true}
                    title={`Контакты ${initialValue?.full_name}`}
                    onClose={handleCloseContactModal}
                >
                    <ClientsContactsView
                        entityType={'client_contacts'}
                        entityId={`${initialValue?.id}`}
                    />
                </AccountModalView>
            )}
            {accountModal && (
                <AccountModal
                    hasContext={true}
                    title={'Счета'}
                    onClose={() => {
                        setAccountModal(false);
                    }}
                >
                    <AccountView entityType={'client'} entityId={`${initialValue?.id}`}/>
                </AccountModal>
            )}

            {modal && (
                <Modal
                    title={initialValue ? 'Изменить клиента' : 'Создать клиента'}
                    onClose={() => {
                        setModal(false);
                        setInitialValue(null);
                    }}
                >
                    <UsersCreateEditView
                        initialValue={initialValue}
                        setModal={setModal}
                    />
                </Modal>
            )}
            {confirmDeleteModal.isModalOpen && (
                <Modal
                    title="Удалить клиента"
                    onClose={() => {
                        setConfirmDeleteModal({isModalOpen: false});
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить клиента ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={clientsLoading}
                    />
                </Modal>
            )}
            {cardModal && (
                <AccountModal
                    title={`Карты ${initialValue?.full_name}`}
                    onClose={handleCloseCardsModal}
                    hasContext
                >
                    <CardsView client={initialValue}/>
                </AccountModal>
            )}
            {documentsModal && (
                <AccountModal
                    title={`Документы ${initialValue?.full_name}`}
                    onClose={handleCloseDocumentsModal}
                    hasContext
                >
                    <ClientDocumentsView client={initialValue}/>
                </AccountModal>
            )}
            {paymentModal && (
                <Modal
                    title={`Провести оплату клиента ${initialValue?.full_name}`}
                    onClose={handleClosePaymentModal}
                >
                    <ClientsDebtsPaymentModalView initialValue={initialValue} setModal={setPaymentModal}/>
                </Modal>
            )}
        </Page>
    );
});

export default ClientsView;
