import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {ClientsDebtsOperationsModel} from "./clients.debts.operations.model";
import ClientsDebtsOperationsStore from "./clients.debts.operations.store";
import {Button} from "../../styles/button";
import {isValid} from "../../utils/isValid";
import {renderInputs} from "../../components/form/render.inputs";

interface ClientsDebtsOperationsCancelModalViewProps {
    initialValue: ClientsDebtsOperationsModel | null;
    setModal: (isOpen: boolean) => void;
}

const ClientsDebtsOperationsCancelModalView = observer(({initialValue, setModal}: ClientsDebtsOperationsCancelModalViewProps) => {

    const {
        cancelPaymentReq,
        cancelOperationForm,
        cancelOperationLoading,
        initClientDebtOperationsPage,
        setForm,
        destroyCancelForm,
    } = ClientsDebtsOperationsStore;

    const {amount, details, oper_date_text} = cancelOperationForm

    const handleConfirm = async () => {
        const controller = new AbortController();
        const response = await cancelPaymentReq(initialValue?.id!, initialValue?.debt_id!, controller.signal)
        if (response) {
            setModal(false);
            await initClientDebtOperationsPage(controller.signal);
            destroyCancelForm()
        }
    }

    const inputs = [
        {field: amount, fieldName: 'amount', placeholder: 'Сумма', type: 'number', required: true},
        {field: details, fieldName: 'details', placeholder: 'Детали', type: 'text', required: false},
        {field: oper_date_text, fieldName: 'oper_date_text', placeholder: 'Дата операции', type: 'date', required: false},
    ]

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(cancelOperationForm, inputs, setForm)}
            </Layout>
            <Layout centered>
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={cancelOperationLoading}
                    onClick={handleConfirm}
                    disabled={isValid([inputs])}
                >{cancelOperationLoading ? '' : 'Потвердить'}</Button>
            </Layout>
        </Layout>
    );
});

export default ClientsDebtsOperationsCancelModalView;