import {makeAutoObservable} from "mobx";
import {PurchaseBillsModel} from "../../purchase.bills/purchase.bills.model";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

interface SupplierPaymentBillModel {
    id: number;
    amount: number;
    oper_date: string;
}

interface SupplierPaymentModel {
    supplier_id: number;
    from_bu_id: number;
    purchase_bills: SupplierPaymentBillModel[];
    total_amount: number;
    details: string;
    oper_date: string;
    show_unpaid_bills: boolean;
}

const formInitialValue: SupplierPaymentModel = {
    supplier_id: 0,
    from_bu_id: 0,
    purchase_bills: [],
    total_amount: 0,
    details: '',
    oper_date: '',
    show_unpaid_bills: false,
}

const loadingInitialState: boolean = false

class SuppliersPaymentModalStore {
    public isLoading = loadingInitialState;
    public form = formInitialValue;
    message: string = '';
    reason: string = '';


    constructor() {
        makeAutoObservable(this)
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    public setForm = (form: SupplierPaymentModel) => {
        this.form = form;
    }

    public destroySupplierPaymentModal = () => {
        this.setLoading(false);
        this.setForm(formInitialValue);
        this.message = '';
        this.reason = '';
    }

    public supplierPaymentReq = async (bills: PurchaseBillsModel[], supplierID: number) => {
        this.setLoading(true)

        const {getToken} = AppStore;

        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        const payload = {
            supplier_id: supplierID,
            from_bu_id: this.form.from_bu_id,
            total_amount: this.form.total_amount,
            details: this.form.details,
            oper_date: this.form.oper_date,
            purchase_bills: [],
        }

        if (this.form.show_unpaid_bills) {
            // @ts-ignore
            payload.purchase_bills = bills.filter((bill) => bill.amount_paid! > 0).map((bill) => ({
                id: bill.id,
                amount: bill.amount_paid,
                oper_date: this.form.oper_date,
            }))
        }

        try {
            const response = await axios.post(api.supplier_transactions, payload, {headers});
            this.message = response?.data.message
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.reason = e?.response?.data.reason;
            toast.error(this.reason);
        } finally {
            this.setLoading(false)
        }
    }
}

export default new SuppliersPaymentModalStore();