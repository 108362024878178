import {SuppliersModel} from "../suppliers.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const initialValues: SuppliersModel = {
    id: 0,
    owner_id: 0,
    name: '',
    phone: '',
    contact_name: '',
    country: '',
    country_id: 1,
    balance: 0,
}

class SuppliersCreateEditStore {
    private countries?: any[];
    public form: SuppliersModel = initialValues;
    public error = '';
    public message = '';
    public isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    public init = async (initialValue: SuppliersModel | null) => {
        await this.countriesReq();
        if (initialValue) {
            this.form = initialValue;
            this.form.country_id = this.countryOptions?.find((item) => this.form.country === item.label)?.value;
        } else {
            this.form = initialValues;
        }
    }

    public createOrEditSupplier = async (edit?: boolean) => {
        this.isLoading = true;
        const payload = {
            id: Number(this.form.id),
            name: this.form.name,
            phone: this.form.phone,
            contact_name: this.form.contact_name,
            country_id: Number(this.form.country_id),
            balance: Number(this.form.balance),
        }
        const {getToken} = AppStore
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            let res;
            if (edit) {
                res = await axios.put(api.suppliers, payload, {headers});
            } else {
                res = await axios.post(api.suppliers, payload, {headers});
            }

            this.message = res?.data.message;
            toast.success(this.message);
            return res;

        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);

        } finally {
            this.isLoading = false;
        }
    }

    private countriesReq = async () => {
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            const filterUrl = `?page=1&order_descending=false`;
            const response = await axios.get(api.get_countries + filterUrl, {headers});
            this.countries = response.data.countries;
        } catch (err) {
        }
    }

    public setField = (form: SuppliersModel) => {
        this.form = form;
    }

    public destroy = () => {
        this.form = initialValues;
    }

    public get countryOptions() {
        if (!this.countries) return;

        return this.countries.map((country) => ({
            label: country.name,
            value: country.id
        }));
    }
}

export default new SuppliersCreateEditStore();