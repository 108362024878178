import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {ShipmentsModel} from "../shipments.model";
import ShipmentsModalStore from "./shipments.modal.store";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import {renderInputs} from "../../../components/form/render.inputs";
import {Text} from "../../../styles/text";
import {TextInput} from "../../../styles/form";
import {modalTextColor, primaryColor} from "../../../utils/variables";
import {useEffect, useState} from "react";
import BusinessUnitsStore from "../../business_units/business_units.store";
import ProductsStore from "../../products/products.store";
import SuppliersStore from "../../suppliers/suppliers.store";
import ShipmentsStore from "../shipments.store";

interface ShipmentsModalViewProps {
    initialValue: ShipmentsModel | null;
    setModal: (isOpen: boolean) => void;
}

interface Tag {
    id: number;
    value: string;
}

const ShipmentsModalView = observer(({initialValue, setModal}: ShipmentsModalViewProps) => {

    const {
        form,
        setForm,
        addSerials,
        destroy,
        shipmentsCreateEditInit,
        shipmentCreateEditReq,
        loading
    } = ShipmentsModalStore;
    const {businessUnits, setBusinessUnitFilter} = BusinessUnitsStore;
    const {products, setProductsFilter} = ProductsStore;
    const {suppliers, setSuppliersFilter} = SuppliersStore;

    const {business_unit_id, product_id, supplier_id, quantity, unit_cost} = form;
    const [tags, setTags] = useState<Tag[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const {getShipmentsReq} = ShipmentsStore

    useEffect(() => {
        destroy();
        shipmentsCreateEditInit(initialValue)
        setBusinessUnitFilter({page: 1, page_limit: 100000})
        setProductsFilter({page: 1, page_limit: 100000})
        setSuppliersFilter({page: 1, page_limit: 100000, technical: true})
        setTags([]);
    }, [shipmentsCreateEditInit, setBusinessUnitFilter, setProductsFilter, setSuppliersFilter]);

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const suppliersOptions = suppliers?.map(
        ({id, name}) => ({label: name, value: id})
    )

    const productsOptions = products?.map(
        ({id, name}) => ({label: name, value: id})
    )

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };

    // Handle adding tags when pressing "Enter" or after pasting
    const handleInputKeyDown = (event: any) => {
        if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
            event.preventDefault();
            addTags(inputValue);
        }
    };

    // Helper function to add tags
    const addTags = (input: string) => {
        const newTags = input
            .split(/[ ,]+/) // Split by space or comma
            .filter(value => value.trim() !== '') // Remove empty values
            .map(value => ({id: Date.now() + Math.random(), value})); // Create unique IDs for each tag

        setTags(prevTags => [...prevTags, ...newTags]);
        setInputValue('');
    };

    // Handle removing a tag
    const handleRemoveTag = (id: number) => {
        setTags(prevTags => prevTags.filter(tag => tag.id !== id));
    };

    const serialList = tags.map(
        ({value}) => (value)
    )

    const handleSubmit = async () => {
        addSerials(serialList)
        const response = await shipmentCreateEditReq(!!initialValue, initialValue?.purchase_bill_id!);

        if (response) {
            destroy();
            setModal(false);
            await getShipmentsReq()
        }
    }

    const handleSelectedProduct = () => {
        return products?.find(product => product.id === product_id)
    }

    const inputs = [
        {
            field: business_unit_id,
            options: businessUnitsOptions,
            fieldName: 'business_unit_id',
            placeholder: 'Подразделение',
            type: 'select',
            required: true
        },
        {
            field: product_id,
            options: productsOptions,
            fieldName: 'product_id',
            placeholder: 'Продукт',
            type: 'select',
            required: true
        },
        {
            field: supplier_id,
            options: suppliersOptions,
            fieldName: 'supplier_id',
            placeholder: 'Поставшик',
            type: 'select',
            required: true
        },
        {
            field: quantity,
            fieldName: 'quantity',
            placeholder: 'Количество',
            type: 'text',
            required: true
        },
        {
            field: unit_cost,
            fieldName: 'unit_cost',
            placeholder: 'Себестоимость',
            type: 'text',
            required: false
        },
    ]

    const handleClearAllClick = () => {
        setTags([]);
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    const handleButtonValidity = () => {
        return isValid([inputs])
    }

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setForm)}
                {handleSelectedProduct()?.has_serial && (
                    <>
                        <Layout centered>
                            <Text weight={'bold'} color={primaryColor}>Серийные номера</Text>
                        </Layout>
                        <Layout hAlign={"space-between"} vAlign={'center'}>
                            <Layout>
                                <Text color={tags.length > quantity ? 'red' : modalTextColor}
                                      extent={'S'}>Количество: {tags.length}</Text>
                            </Layout>
                            <Layout>
                                <Button
                                    onClick={handleClearAllClick}
                                >
                                    Очистить все
                                </Button>
                            </Layout>
                        </Layout>
                        <div style={{
                            border: '2px solid #ddd',
                            padding: '8px',
                            marginTop: '10px',
                            width: '100%',
                            borderRadius: '10px',
                            backgroundColor: "rgba(0, 0, 0, .03)",
                            color: '#333'
                        }}>
                            <TextInput
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleInputKeyDown}
                                placeholder="Введите все серийные номера через пробел или запятая"
                                style={{border: 'none', outline: 'none', width: '100%'}}
                            />
                            <Layout style={{display: 'flex', flexWrap: 'wrap', marginTop: '10px'}}>
                                {tags.map(tag => (
                                    <div
                                        key={tag.id}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            background: '#e0e0e0',
                                            borderRadius: '8px',
                                            padding: '5px',
                                            margin: '5px',
                                        }}
                                    >
                                        <span>{tag.value}</span>
                                        <button
                                            onClick={() => handleRemoveTag(tag.id)}
                                            style={{
                                                marginLeft: '5px',
                                                background: 'none',
                                                border: 'none',
                                                cursor: 'pointer',
                                                color: '#888',
                                            }}
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}
                            </Layout>
                        </div>
                    </>
                )}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={loading}
                    onClick={handleSubmit}
                    disabled={handleButtonValidity()}
                >{loading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    );
});

export default ShipmentsModalView;