import {makeAutoObservable} from "mobx";
import axios, { GenericAbortSignal } from "axios";
import {api} from "../../api/endpoints";
import AppStore from "../../app.store";
import {UsersModel, UserFilterModel} from "./users.model";
import { toast } from "react-toastify";

const usersFilterInitialState = {
    search: '',
    role_id: 0,
    business_unit_id: 0,
    active: '',
    page: 1,
    page_limit: 25,
    pages: 0,
    order_by: 'surname',
    order_descending: false,
}

const usersInitialState: UsersModel[] = [];
const loadingInitialState: boolean = false;

class UsersStore {
    public users ?: UsersModel[] = usersInitialState;
    public usersFilter = usersFilterInitialState;
    public usersLoading: boolean = loadingInitialState;
    public message = "";
    public error = "";

    public usersPageInit = async (signal: GenericAbortSignal) => {
        void this.usersReq(signal);
    }
    
    constructor() {
        makeAutoObservable(this);
    }

    setUsers(data: any) {
        const {users} = data;
        
        this.users = users;
    }

    public usersReq = async (signal?: GenericAbortSignal) => {
        this.setLoading(true);
        try {
            const {getToken} = AppStore

            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            }

            const filterUrl = Object.keys(this.usersFilter).reduce((filters, filter) => {
                const symbol = filters ? '&' : '?';
                const filterKeyName = filter as keyof UserFilterModel;
                if (this.usersFilter[filterKeyName] !== 0 &&
                    this.usersFilter[filterKeyName] !== '') {
                    filters += symbol + filter + '=' + this.usersFilter[filterKeyName];
                }

                return filters;
            }, '');

            const response = await axios.get(api.get_users + filterUrl, {headers, signal});
            this.setUsers(response.data)
            this.setUserFilterPages(response.data.pages)
        } catch (err) {
        } finally {
            this.setLoading(false);
        }
    }

    public setUsersFilter = ({search, role_id, business_unit_id, active, page, page_limit}: UserFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.usersFilter = {
            ...this.usersFilter,
            search: isUndefined(search) ? this.usersFilter.search! : search!,
            role_id: isUndefined(role_id) ? this.usersFilter.role_id! : role_id!,
            business_unit_id: isUndefined(business_unit_id) ? this.usersFilter.business_unit_id! : business_unit_id!,
            active: isUndefined(active) ? this.usersFilter.active! : active!,
            page: isUndefined(page) ? this.usersFilter.page! : page!,
            page_limit: isUndefined(page_limit) ? this.usersFilter.page_limit! : page_limit!
        };

        if (page_limit) {
            this.usersFilter.page = 1;
        }

        void this.usersReq();
    }

    public deleteUser = async (id: number) => {
        this.setLoading(true);
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            const response = await axios.delete(`${api.user}/${id}`, {headers});
            // if (res?.code >= 400) {
            //     throw new Error(res)
            // }
            this.message = response?.data.message;
            toast.success(this.message);

            if (response) {
               void this.usersReq();
            }
        } catch (err:any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }

        await this.usersReq();
    }

    private setUserFilterPages = (pages: number) => {
        this.usersFilter.pages = pages;
    }

    private setLoading = (loading: boolean) => {
        this.usersLoading = loading;
    }

    public clearFilter = () => {
        this.usersFilter = usersFilterInitialState;
        void this.usersReq();
    }
}

export default new UsersStore();