import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {PurchaseBillsModel} from "../purchase.bills.model";
import PurchaseBillsModalStore from "./purchase.bills.modal.store";
import React, {useEffect, useState} from "react";
import {renderInputs} from "../../../components/form/render.inputs";
import {Text} from "../../../styles/text";
import {modalTextColor, primaryColor} from "../../../utils/variables";
import {SelectView, TextInput} from "../../../styles/form";
import {Table, TableBody, TableContainer, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import {Select, Switch} from "antd";
import ProductsStore from "../../products/products.store";
import {ShipmentsModel} from "../../shipments/shipments.model";
import {Button} from "../../../styles/button";
import SuppliersStore from "../../suppliers/suppliers.store";
import {isValid} from "../../../utils/isValid";
import BusinessUnitsStore from "../../business_units/business_units.store";
import AppStore from "../../../app.store";
import ModalView from "../../../components/modal/modal.view";
import PurchaseBillsModalSerialsView from "./purchase.bills.modal.serials.view";
import PurchaseBillsStore from "../purchase.bills.store";

interface PurchaseBillsModalViewProps {
    initialValues: PurchaseBillsModel | null;
    setModal: (isOpen: boolean) => void;
}

const PurchaseBillsModalView = observer(({initialValues, setModal}: PurchaseBillsModalViewProps) => {

    const {
        form,
        isLoading,
        productsList,
        destroy,
        setForm,
        addProductToList,
        deleteProductFromList,
        initPurchaseBillsCreateEdit,
        changeProductField,
        setBillField,
        createEditBillReq,
    } = PurchaseBillsModalStore;
    const {getBillsReq} = PurchaseBillsStore
    const {businessSettings} = AppStore;

    const {products, setProductsFilter} = ProductsStore;
    const {suppliers, setSuppliersFilter} = SuppliersStore;
    const {businessUnits, setBusinessUnitFilter} = BusinessUnitsStore;
    const [calculateByDimensions, setCalculateByDimensions] = useState(false);
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [otherExpensesInPercent, setOtherExpensesInPercent] = useState(false);
    const [allocationMethod, setAllocationMethod] = useState(1);
    const [serialsModal, setSerialsModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ShipmentsModel | null>(null);
    const [productIndex, setProductIndex] = useState(0);

    useEffect(() => {
        initPurchaseBillsCreateEdit(initialValues);
        setProductsFilter({page: 1, page_limit: 100000, type_id: 1});
        setSuppliersFilter({page: 1, page_limit: 100000, technical: true});
        setBusinessUnitFilter({page: 1, page_limit: 100000});

        if (!calculateByDimensions) {
            setForm({
                ...form,
                cbm_price: 0,
                weight_price: 0,
                exchange_rate: 1,
            })
        }

        if (otherExpensesInPercent) {
            setAllocationMethod(0);
        }

    }, [initPurchaseBillsCreateEdit, setProductsFilter, setSuppliersFilter]);

    const {
        supplier_id,
        document_number,
        document_date_text,
        business_unit_id,
        cbm_price,
        extra_expenses,
        weight_price
    } = form;

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    )

    const productsOptions = products?.map(
        (
            {
                id,
                name,
                brand,
                has_serial,
                length,
                width,
                height,
                quantity_in_package,
                package_weight
            }
        ) => (
            {
                label: `${name}      |      ${brand}`,
                value: id,
                brand: brand,
                name: name,
                has_serial: has_serial,
                length: length,
                width: width,
                height: height,
                quantity_in_pack: quantity_in_package,
                pack_weight: package_weight,
                id: id,
            }
        ))

    const suppliersOptions = suppliers?.map(
        ({id, name}) => ({label: name, value: id})
    )

    const inputs = [
        {
            field: supplier_id,
            fieldName: 'supplier_id',
            placeholder: 'Поставщик',
            type: 'select',
            required: true,
            options: suppliersOptions,
        },
        {
            field: business_unit_id,
            fieldName: 'business_unit_id',
            placeholder: 'Подразделение',
            type: 'select',
            required: true,
            options: businessUnitsOptions,
        },
        {
            field: document_number,
            fieldName: 'document_number',
            placeholder: 'Номер документа',
            type: 'text',
            required: false
        },
        {
            field: document_date_text,
            fieldName: 'document_date_text',
            placeholder: 'Дата документа',
            type: 'date',
            required: false
        },
    ];

    const handleSubmit = async () => {
        const response = await createEditBillReq();
        if (response) {
            await getBillsReq();
            setModal(false);
            destroy()
        }
    }

    const handleSelectProduct = (product: any) => {
        const shipment: ShipmentsModel = {
            business_unit_id: business_unit_id,
            business_unit_name: "",
            created_at_text: "",
            product_id: product.id,
            product_name: product.name,
            purchase_bill_id: 0,
            quantity: 1,
            remaining: 0,
            shipment_owner_id: 0,
            supplier_id: supplier_id,
            supplier_name: "",
            unit_cost: 0,
            user_id: 0,
            user_name: "",
            id: 0,
            brand: product.brand,
            length: product.length,
            width: product.width,
            height: product.height,
            quantity_in_pack: product.quantity_in_pack,
            pack_weight: product.pack_weight,
            original_cost: 1,
            extra_expense: 0,
            cost_by_cbm: 0,
            cost_by_weight: 0,
            total: 0,
            has_serial: product.has_serial,
            serials: [],
        }
        setSelectedOption(null);
        addProductToList(shipment)
    }

    const handleDeleteProductClick = (index: number) => {
        deleteProductFromList(index);
        handleExtraExpenseAmount(otherExpensesInPercent, extra_expenses);
        handleProductUnitCost();
    }

    const handleChangeProduct = (index: number, field: string, value: string | number) => {
        changeProductField(index, field, value);
        handleExtraExpenseAmount(otherExpensesInPercent, extra_expenses);
        handleProductUnitCost();
    }

    const handleTotalAmount = () => {
        let totalAmount: number = 0;
        productsList.reduce((_: any, product) => {
            totalAmount += Number(product.quantity) * Number(product.unit_cost);
            return totalAmount.toFixed(2);
        }, 0)
        setBillField('total', totalAmount);
        return totalAmount | 0;
    }

    const handleTotalOriginalAmount = () => {
        let totalAmount: number = 0;
        productsList.reduce((_: any, product) => {
            totalAmount += product.quantity * product.original_cost;
            return totalAmount.toFixed(2);
        }, 0)
        setBillField('total', totalAmount);
        return totalAmount | 0;
    }

    const handleTotalQuantity = () => {
        let totalQuantity: number = 0;
        productsList.reduce((_: any, product) => {
            totalQuantity += Number(product.quantity);
            return totalQuantity;
        }, 0)
        return totalQuantity | 0;
    }

    const handleCalculateByDimensionsToggle = () => {
        setCalculateByDimensions(!calculateByDimensions)
    }

    const handleChangeCbmPrice = (value: number) => {
        setForm({...form, cbm_price: value});
        handleCostByCbm(value)
        handleProductUnitCost();
    }

    const handleChangeWeightPrice = (value: any) => {
        setForm({...form, weight_price: value});
        handleCostByWeight(value)
        handleProductUnitCost();
    }

    const handleCostByCbm = (value: number) => {
        productsList.forEach((product, index) => {
            const cost = ((product.length! / 100) * (product.width! / 100) * (product.height! / 100) * value) / product.quantity_in_pack!
            changeProductField(index!, 'cost_by_cbm', cost.toFixed(2))
        })

    }

    const handleCostByWeight = (value: number) => {
        productsList.forEach((product, index) => {
            const cost = product.pack_weight! * value / product.quantity_in_pack!
            changeProductField(index!, 'cost_by_weight', cost.toFixed(2));
        })
    }

    const handleFinalDeliveryCostValue = (product: ShipmentsModel) => {
        return Math.max(product.cost_by_weight, product.cost_by_cbm)
    }

    const handleOtherCostsChange = (value: number) => {
        setForm({...form, extra_expenses: value})
        handleExtraExpenseAmount(otherExpensesInPercent, value);
        handleProductUnitCost();
    }

    const handleExtraExpenseAmount = (expenseInPercent: boolean, extraExpenseValue: number) => {
        const totalAmount = handleTotalOriginalAmount();
        const totalQuantity = handleTotalQuantity();
        const productsLength = productsList.length!

        if (expenseInPercent) {
            productsList.forEach((product, index) => {
                const deliveryCost = handleFinalDeliveryCostValue(product)
                const otherExpensesAmount = (extraExpenseValue * (product.original_cost! + deliveryCost) / 100) / product.quantity;
                changeProductField(index, 'extra_expense', otherExpensesAmount.toFixed(2))
            })
        } else {
            if (allocationMethod === 1) {
                productsList.forEach((product, index) => {
                    const otherExpensesAmount = (extraExpenseValue * product.quantity * product.original_cost! / (totalAmount)) / product.quantity;
                    changeProductField(index, 'extra_expense', Number(otherExpensesAmount.toFixed(2)))
                })
            } else if (allocationMethod === 2) {
                productsList.forEach((product, index) => {
                    const otherExpensesAmount = (extraExpenseValue * product.quantity / (totalQuantity)) / product.quantity;
                    changeProductField(index, 'extra_expense', Number(otherExpensesAmount.toFixed(2)))
                })
            } else if (allocationMethod === 3) {
                productsList.forEach((product, index) => {
                    const otherExpensesAmount = (extraExpenseValue / productsLength) / product.quantity;
                    changeProductField(index, 'extra_expense', Number(otherExpensesAmount.toFixed(2)))
                })
            }
        }
    }

    const handleExtraCostsToggle = (value: boolean) => {
        setOtherExpensesInPercent(!otherExpensesInPercent)
        handleExtraExpenseAmount(value, extra_expenses);
    }

    const handleChangeAllocationMethod = (value: number) => {
        setAllocationMethod(value);
        handleExtraExpenseAmount(otherExpensesInPercent, extra_expenses);
        handleProductUnitCost();
    }

    const handleProductUnitCost = () => {
        productsList.forEach((product, index) => {
            const unitCost = Number(product.original_cost!) + Number(product.extra_expense!) + handleFinalDeliveryCostValue(product);
            changeProductField(index, 'unit_cost', unitCost.toFixed(2));
        })
    }

    const handleSerialsClick = (shipment: ShipmentsModel, index: number) => {
        setInitialValue(shipment);
        setProductIndex(index);
        setSerialsModal(true);
    }

    const handleCloseSerialsModal = () => {
        setInitialValue(null);
        setProductIndex(0);
        setSerialsModal(false);
    }

    return (
        <Layout vertical between={20}>
            <Layout
                hAlign={"space-around"}
                between={50}
            >
                <Layout vertical between={10} widthSize={'33.3%'}>
                    {renderInputs(form, inputs, setForm)}
                </Layout>
                {businessSettings?.product_dimensions && (
                    <Layout
                        vertical
                        widthSize={'33.3%'}
                        between={10}
                    >
                        <Layout hAlign={"space-between"} vAlign={"center"} between={10}>
                            <Layout widthSize={'35%'}>
                                <Text extent={'S'} color={modalTextColor}>Считать по размерам и курсу</Text>
                            </Layout>
                            <Layout widthSize={'65%'}>
                                <Switch
                                    onChange={handleCalculateByDimensionsToggle}
                                    checked={calculateByDimensions}
                                />
                            </Layout>
                        </Layout>
                        <Layout hAlign={"space-between"} vAlign={"center"} between={10}>
                            <Layout widthSize={'35%'}>
                                <Text extent={'S'} color={modalTextColor}>Цена за куб</Text>
                            </Layout>
                            <Layout widthSize={'65%'}>
                                <TextInput
                                    value={cbm_price}
                                    onChange={(e) => handleChangeCbmPrice(+e.target.value)}
                                    disabled={!calculateByDimensions}
                                />
                            </Layout>
                        </Layout>
                        <Layout hAlign={"space-between"} vAlign={"center"} between={10}>
                            <Layout widthSize={'35%'}>
                                <Text extent={'S'} color={modalTextColor}>Цена за кг</Text>
                            </Layout>
                            <Layout widthSize={'65%'}>
                                <TextInput
                                    value={weight_price}
                                    onChange={(e) => handleChangeWeightPrice(e.target.value)}
                                    disabled={!calculateByDimensions}
                                />
                            </Layout>
                        </Layout>
                        {/*<Layout hAlign={"space-between"} vAlign={"center"} between={10}>
                            <Layout widthSize={'35%'}>
                                <Text extent={'S'} color={modalTextColor}>Курс</Text>
                            </Layout>
                            <Layout widthSize={'65%'}>
                                <TextInput
                                    value={exchangeRate}
                                    onChange={(e) => handleChangeExchangeRate(+e.target.value)}
                                    disabled
                                    //disabled={!calculateByDimensions}
                                />
                            </Layout>
                        </Layout>*/}
                    </Layout>
                )}
                <Layout
                    vertical
                    widthSize={'33.3%'}
                    between={10}
                >
                    <Layout hAlign={"space-between"} vAlign={"center"} between={10}>
                        <Layout widthSize={'35%'}>
                            <Text extent={'S'} color={modalTextColor}>Доп. расходы</Text>
                        </Layout>
                        <Layout widthSize={'65%'}>
                            <TextInput
                                value={extra_expenses}
                                onChange={(e) => handleOtherCostsChange(+e.target.value)}
                            />
                        </Layout>

                    </Layout>
                    <Layout hAlign={"space-between"} vAlign={"center"} between={10}>
                        <Layout widthSize={'35%'}>
                            <Text extent={'S'} color={modalTextColor}>Доп. расходы в процентах?</Text>
                        </Layout>
                        <Layout widthSize={'65%'}>
                            <Switch
                                onChange={(checked) => handleExtraCostsToggle(checked)}
                                checked={otherExpensesInPercent}
                            />
                        </Layout>
                    </Layout>
                    {!otherExpensesInPercent && (
                        <Layout hAlign={"space-between"} vAlign={"center"} between={10}>
                            <Layout widthSize={'35%'}>
                                <Text extent={'S'} color={modalTextColor}>Распределеине расхода между товарами</Text>
                            </Layout>
                            <Layout widthSize={'65%'}>
                                <SelectView
                                    onChange={(e) => handleChangeAllocationMethod(+e.target.value)}
                                    value={allocationMethod}
                                >
                                    <option value={1}>По сумме</option>
                                    <option value={2}>По количеству</option>
                                    <option value={3}>Равномерно</option>
                                </SelectView>
                            </Layout>
                        </Layout>
                    )}
                </Layout>
            </Layout>
            <Layout centered>
                <Text extent={'M'} weight={'bold'} color={primaryColor}>Товары</Text>
            </Layout>
            <TableContainer
                style={{maxHeight: '40vh'}}
            >
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell style={{width: '30%'}}>Название продукта</THeadCell>
                            <THeadCell style={{width: '20%'}}>Бренд</THeadCell>
                            {businessSettings?.product_dimensions && (
                                <>
                                    <THeadCell style={{width: '8%'}}>Размеры (см)</THeadCell>
                                    <THeadCell style={{width: '8%'}}>Вес упаковки</THeadCell>
                                    <THeadCell style={{width: '8%'}}>К-во в упаковке</THeadCell>
                                </>
                            )}
                            {calculateByDimensions && (
                                <>
                                    <THeadCell style={{width: '8%'}}>Расход по кубу</THeadCell>
                                    <THeadCell style={{width: '8%'}}>Расход по весу</THeadCell>
                                </>
                            )}
                            <THeadCell style={{width: '8%'}}>Количество</THeadCell>
                            <THeadCell style={{width: '12%'}}>Оригинальная себестоимость</THeadCell>
                            {calculateByDimensions && (
                                <THeadCell style={{width: '8%'}}>Расход доставки (единица)</THeadCell>
                            )}
                            <THeadCell style={{width: '8%'}}>Доп. расходы (единица)</THeadCell>
                            <THeadCell style={{width: '15%'}}>Итоговая себестоимость</THeadCell>
                            <THeadCell style={{width: '5%'}}></THeadCell>
                        </TRow>
                    </TableHead>
                    <TableBody>
                        {productsList.map((product, index) => (
                            <TRow key={index} menu>
                                <TCell>
                                    {product.product_name}
                                </TCell>
                                <TCell>
                                    {product.brand}
                                </TCell>
                                {businessSettings?.product_dimensions && (
                                    <>
                                        <TCell>{`${product.length}x${product.width}x${product.height}`}</TCell>
                                        <TCell>{product.pack_weight}</TCell>
                                        <TCell>{product.quantity_in_pack}</TCell>
                                    </>
                                )}
                                {
                                    calculateByDimensions && (
                                        <>
                                            <TCell>{product.cost_by_cbm}</TCell>
                                            <TCell>{product.cost_by_weight}</TCell>
                                        </>
                                    )
                                }
                                <TCell>
                                    <Layout between={5} vertical>
                                        <TextInput
                                            value={product.quantity}
                                            style={{width: '100%'}}
                                            onChange={(e) => handleChangeProduct(index, 'quantity', e.target.value)}
                                        />
                                        {product.has_serial && (
                                            <Text
                                                onClick={() => handleSerialsClick(product, index)}
                                                style={{cursor: 'pointer', fontStyle: 'italic'}}
                                                color={primaryColor}
                                            >
                                                Серийные номера
                                            </Text>
                                        )}
                                    </Layout>
                                </TCell>
                                <TCell>
                                    <TextInput
                                        value={product.original_cost}
                                        style={{width: '100%'}}
                                        onChange={(e) => handleChangeProduct(index, 'original_cost', e.target.value)}
                                    />
                                </TCell>
                                {calculateByDimensions && (
                                    <TCell>
                                        <TCell>{Math.max(product.cost_by_cbm, product.cost_by_weight)}</TCell>
                                    </TCell>
                                )}
                                <TCell>
                                    <TextInput
                                        disabled
                                        value={product.extra_expense}
                                    />
                                </TCell>
                                <TCell>
                                    <>
                                        <TextInput
                                            value={product.unit_cost}
                                            disabled
                                            style={{width: '100%'}}
                                        />
                                    </>

                                </TCell>
                                <TCell>
                                    <Button onClick={() => handleDeleteProductClick(index)}>-</Button>
                                </TCell>
                            </TRow>
                        ))}
                        <TRow menu>
                            <TCell>
                                <Select
                                    showSearch
                                    options={productsOptions}
                                    showArrow={false}
                                    filterOption={(inputValue, option: any) =>
                                        (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                    placeholder={'Выберите продукт'}
                                    onChange={(_, option) => {
                                        handleSelectProduct(option)
                                    }}
                                    style={{
                                        width: '100%',
                                    }}
                                    value={selectedOption}
                                />
                            </TCell>
                            <TCell></TCell>
                            {businessSettings?.product_dimensions && (
                                <>
                                    <TCell></TCell>
                                    <TCell></TCell>
                                    <TCell></TCell>
                                </>
                            )}
                            {calculateByDimensions && (
                                <>
                                    <TCell></TCell>
                                    <TCell></TCell>
                                    <TCell></TCell>
                                </>
                            )}
                            <TCell></TCell>
                            <TCell></TCell>
                            <TCell></TCell>
                            <TCell></TCell>
                            <TCell></TCell>
                        </TRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Layout hAlign={"space-between"}>
                <Layout hAlign={"space-between"} vAlign={"center"} between={10}>
                    <Layout widthSize={'35%'}>
                        <Text extent={'S'} color={modalTextColor}>Общее количество</Text>
                    </Layout>
                    <Layout widthSize={'65%'}>
                        <TextInput
                            value={handleTotalQuantity()}
                            disabled
                        />
                    </Layout>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"} between={10}>
                    <Layout widthSize={'35%'}>
                        <Text extent={'S'} color={modalTextColor}>Общая сумма</Text>
                    </Layout>
                    <Layout widthSize={'65%'}>
                        <TextInput
                            value={handleTotalAmount()}
                            disabled
                        />
                    </Layout>
                </Layout>
            </Layout>
            <Layout centered>
                <Button
                    isLoading={isLoading}
                    disabled={isValid(inputs) || isLoading}
                    onClick={handleSubmit}
                >
                    {isLoading ? '' : 'Потвердить'}
                </Button>
            </Layout>
            {serialsModal && (
                <ModalView
                    title={`Серийные номера товара ${initialValue?.product_name}`}
                    onClose={handleCloseSerialsModal}
                >
                    <PurchaseBillsModalSerialsView initialValue={initialValue} setModal={setSerialsModal}
                                                   index={productIndex}/>
                </ModalView>
            )}
        </Layout>
    );
});

export default PurchaseBillsModalView;