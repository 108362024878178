import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {Search, SearchLayout, Select} from "../../styles/table-header";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";
import {DatePicker, Input, Spin} from "antd";
import {borderRadius, textColorDark} from "../../utils/variables";
import {Select as AntSelect} from 'antd';
import React from "react";
import {TextInput} from "../../styles/form";

interface FiltersProps {
    filterInputs: any[];
    isInModal: boolean;
    handleFilterChange?: (e: any, key: string, index?: number, antSelect?: boolean) => void
    setDates?: (e: any) => void;
    handleKeyDown?: (e: any) => void;
    cancelSearch?: (e: any) => void;
    filters: any;
}

const FilterView = observer(({
                                 filterInputs,
                                 handleFilterChange,
                                 setDates,
                                 isInModal,
                                 handleKeyDown,
                                 cancelSearch,
                                 filters
                             }: FiltersProps) => {

    const {RangePicker} = DatePicker;

    const selectStyle: React.CSSProperties = {
        width: '180px',
        height: '40px',
        borderRadius: borderRadius,
        border: '1px #e5e5e5',
        backgroundColor: '#ffffff',
        fontSize: '14px',
        color: '#374151',
        //   -webkit-appearance: none,
        //   -moz-appearance: none,
        position: 'relative',
        padding: '0 15px',
        backgroundImage: `url("data:image/svg+xml,utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '100%',
        backgroundPositionY: '8px',
        cursor: 'pointer',
        fontFamily: 'Open-Sans, Helvetica, Sans-Serif',
        verticalAlign: 'center',
    }

    document.body.addEventListener("wheel", (ev) => {
        const target = ev.composedPath()[0] as HTMLElement;
        if (target.matches('input[type="number"]')) {
            target.blur();
        }
    });

    return (
        <Layout between={10} wrapped vAlign="center" vertical={isInModal}>
            {filterInputs.map((input, index) => {
                const {fieldName, placeholder, type, options, defaultValue, hasSearch, loading, onFocus} = input;

                switch (type) {
                    case 'search':
                        return (
                            <SearchLayout
                                key={index}
                            >
                                <Search
                                    type="search"
                                    id="search"
                                    placeholder={placeholder}
                                    onKeyDown={(e) => handleKeyDown!(e)}
                                    onChange={(e) => cancelSearch!(e)}
                                    value={filters[fieldName]}
                                />
                                <Image src={SearchIcon} height="16px"/>
                            </SearchLayout>
                        );
                    case 'select':
                        return (
                            hasSearch ?
                                <AntSelect
                                    showSearch
                                    placeholder={placeholder}
                                    filterOption={(inputValue, option: any) =>
                                        (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                    notFoundContent={loading ? <Layout centered><Spin size={'default'}/></Layout> :
                                        <Layout centered> Данных нет</Layout>}
                                    options={options}
                                    style={selectStyle}
                                    showArrow={false}
                                    onChange={(e) => handleFilterChange?.(e, fieldName, 0, true)}
                                    loading={loading}
                                    onFocus={onFocus}
                                    allowClear
                                    bordered={false}
                                />
                                :
                                <Select
                                    id={`${fieldName}-filter`}
                                    style={{width: 150}}
                                    onChange={(e) => handleFilterChange!(e, fieldName)}
                                    key={index}
                                    value={filters[fieldName]}
                                    defaultValue={defaultValue}
                                    onFocus={onFocus}
                                >
                                    {placeholder && <option value="">{placeholder}</option>}
                                    {options?.map((option: any, index: number) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Select>
                        );
                    case 'range_picker':
                        return (
                            <RangePicker
                                onChange={(e) => {
                                    setDates?.(e);
                                }}
                                placeholder={['Начало даты', 'Конец даты']}
                                style={{
                                    height: 40,
                                    width: 250,
                                    borderRadius: 12,
                                    border: 0,
                                    borderWidth: 1,
                                    borderColor: '#E5E5E5',
                                    color: '#374151',
                                    backgroundColor: '#FFFFFF',
                                }}
                                key={index}
                                popupStyle={{
                                    fontFamily: 'Open-Sans, Helvetica, Sans-Serif',
                                    color: textColorDark,
                                }}
                                //    defaultValue={[dateFromJS, dateToJS]}
                            />
                        );
                    case 'number':
                        return (
                            <Layout>
                                <TextInput
                                    value={filters[fieldName]}
                                    onChange={(e) => {
                                        handleFilterChange!(e, fieldName)}
                                }
                                    placeholder={placeholder}
                                />
                            </Layout>

                        );
                    default:
                        return null
                }
            })}
        </Layout>
    );
});

export default FilterView;