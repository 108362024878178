import {observer} from "mobx-react";
import AppStore from "../../app.store";
import {Page} from "../../styles/page";
import ClientsDebtsOperationsStore from "./clients.debts.operations.store";
import {useEffect, useState} from "react";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import BusinessUnitsStore from "../business_units/business_units.store";
import UsersStore from "../users/users.store";
import ClientsStore from "../clients/clients.store";
import {Headline} from "../../styles/headline";
import {primaryColor} from "../../utils/variables";
import {Filters} from "../../styles/filters";
import FilterView from "../../components/filters/filters.view";
import {FilterButton} from "../../styles/button";
import {TableHeader} from "../../styles/table-header";
import TableDesktopView from "../../components/table/render.table.desktop";
import TableMobileView from "../../components/table/render.table.mobile";
import TablePaginationView from "../../utils/table.pagination.view";
import ModalView from "../../components/modal/modal.view";
import {ClientsDebtsOperationsModel} from "./clients.debts.operations.model";
import ClientsDebtsOperationsCancelModalView from "./clients.debts.operations.cancel.modal.view";

const ClientsDebtsOperationsView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        clientDebtOperationsLoading,
        setClientDebtOperationsFilters,
        clientDebtsOperations,
        clientDebtOperationsFilters,
        initClientDebtOperationsPage,
        destroyCancelForm,
    } = ClientsDebtsOperationsStore;
    const {businessUnits, setBusinessUnitFilter, businessUnitsLoading} = BusinessUnitsStore;
    const {users, setUsersFilter, usersLoading} = UsersStore;
    const {clients, setClientsFilter, clientsLoading} = ClientsStore;
    const {destroyDictionary, dictionaryListReq, getPageTitle, dictionaryList} = DictionaryStore;
    const [filterModal, setFilterModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ClientsDebtsOperationsModel | null>(null);
    const [cancelModal, setCancelModal] = useState(false);

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    )

    const usersOptions = users?.map(
        ({id, full_name}) => ({label: full_name, value: id})
    )

    const clientsOptions = clients?.map(
        ({id, full_name}) => ({label: full_name, value: id})
    )


    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        void dictionaryListReq('CLIENT_DEBT_OPERATIONS', 'TABLE HEADERS', controller.signal);
        void initClientDebtOperationsPage(controller.signal);

        return () => controller.abort();
    }, [destroyDictionary, dictionaryListReq, initClientDebtOperationsPage]);

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && clientDebtOperationsFilters.search !== '') {
            setClientDebtOperationsFilters({search: ''});
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setClientDebtOperationsFilters({search: e.target.value});
        }
    };

    const setDates = (e: any) => {
        if (e !== null) {
            setClientDebtOperationsFilters({date_to: e[1].format('YYYY-MM-DD')});
            setClientDebtOperationsFilters({date_from: e[0].format('YYYY-MM-DD')});
        } else {
            setClientDebtOperationsFilters({date_from: ''});
            setClientDebtOperationsFilters({date_to: ''});
        }
    };

    const handleFilterChange = (e: any, objectKey: string, index?: number, antSelect?: boolean) => {
        if (objectKey === 'page' && index !== undefined) {
            setClientDebtOperationsFilters({page: index + 1});
            return;
        }

        if (!antSelect) {
            const {
                target: {options, selectedIndex},
            } = e;

            setClientDebtOperationsFilters({[objectKey]: options[selectedIndex].value});
        } else {
            setClientDebtOperationsFilters({[objectKey]: e});
        }
    };

    const prevPage = () => {
        if (clientDebtOperationsFilters.page! > 1) {
            setClientDebtOperationsFilters({page: clientDebtOperationsFilters.page! - 1});
        }
    };

    const nextPage = () => {
        if (clientDebtOperationsFilters.page! < clientDebtOperationsFilters.pages!) {
            setClientDebtOperationsFilters({page: clientDebtOperationsFilters.page! + 1});
        }
    };

    const pageLimits = [25, 50, 100, 250, 500];

    const filterInputs = [
        {
            type: 'range_picker',
        },
        {
            fieldName: 'search',
            placeholder: 'Поиск',
            type: 'search',
        },
        {
            fieldName: 'business_unit_id',
            placeholder: 'Все подразделения',
            type: 'select',
            options: businessUnitsOptions,
            hasSearch: true,
            loading: businessUnitsLoading,
            onFocus: () => setBusinessUnitFilter({page: 1, page_limit: 1000000, return_balance: false}),
        },
        {
            fieldName: 'user_id',
            placeholder: 'Все сотрудники',
            type: 'select',
            options: usersOptions,
            hasSearch: true,
            loading: usersLoading,
            onFocus: () => setUsersFilter({page: 1, page_limit: 100000}),
        },
        {
            fieldName: 'client_id',
            placeholder: 'Все клиенты',
            type: 'select',
            options: clientsOptions,
            hasSearch: true,
            loading: clientsLoading,
            onFocus: () => setClientsFilter({page: 1, page_limit: 1000000}),
        },
        /*{
            fieldName: 'status',
            placeholder: 'Все долги',
            type: 'select',
            options: statusOption,
        },*/
    ];

    const handleClickCancelOperationOption = (operation: ClientsDebtsOperationsModel) => {
        setInitialValue(operation);
        setCancelModal(true)
    }

    const handleCancelOperationConditions = (operation: ClientsDebtsOperationsModel) => {
        return operation.type_id === 2
    }

    const handleCloseCancelModal = () => {
        setInitialValue(null);
        setCancelModal(false);
        destroyCancelForm();
    }

    const contentOptions = [
        {
            name: 'Отменить',
            onClick: (debtOperation: ClientsDebtsOperationsModel) => handleClickCancelOperationOption(debtOperation),
            condition: (debtOperation: ClientsDebtsOperationsModel) => handleCancelOperationConditions(debtOperation),
        }
    ]

    return (
        <Page isNavOpen={isNavOpen} fadeIn vertical between={20}>
            <Headline color={primaryColor}>{getPageTitle('CLIENT_DEBT_OPERATIONS')}</Headline>
            <TableHeader>
                <Filters hAlign="space-between" wrapped between={10}>
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={false}
                        filters={clientDebtOperationsFilters}
                        handleFilterChange={handleFilterChange}
                        setDates={setDates}
                        cancelSearch={cancelSearch}
                        handleKeyDown={handleKeyDown}
                    />
                </Filters>
                <FilterButton
                    id={'client-debts-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
            </TableHeader>
            <TableDesktopView
                data={clientDebtsOperations}
                headers={dictionaryList}
                loading={clientDebtOperationsLoading}
                hasContext
                contextOptions={contentOptions}
            />
            <TableMobileView
                data={clientDebtsOperations}
                headers={dictionaryList}
                loading={clientDebtOperationsLoading}
                hasContext
                contextOptions={contentOptions}
            />
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={clientDebtOperationsFilters.pages}
                currentPage={clientDebtOperationsFilters.page!}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {filterModal && (
                <ModalView
                    title={'Фильтры'}
                    onClose={() => setFilterModal(false)}
                >
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={true}
                        filters={clientDebtOperationsFilters}
                        handleFilterChange={handleFilterChange}
                        setDates={setDates}
                        cancelSearch={cancelSearch}
                        handleKeyDown={handleKeyDown}
                    />
                </ModalView>
            )}
            {cancelModal && (
                <ModalView
                    title={'Отмена операции'}
                    onClose={handleCloseCancelModal}
                >
                    <ClientsDebtsOperationsCancelModalView initialValue={initialValue} setModal={setCancelModal}/>
                </ModalView>
            )}
        </Page>
    );
});

export default ClientsDebtsOperationsView;