import axios from 'axios';
import {makeAutoObservable} from 'mobx';
import { api } from '../../../api/endpoints';
import AppStore from '../../../app.store';
import { ClientsModel } from '../clients.model';
import { toast } from 'react-toastify';

const initialValues: ClientsModel = {
    email: '',
    full_name: '',
    pseudonym: '',
    patronymic: '',
    phone: '',
    id: 0,
    client_owner_id: 0,
    address: '',
    current_address: '',
    company: '',
    inn: '',
    type_id: 0,
    client_types: '',
    balance: 0,
};

class ClientsCreateEditStore {
    public form: ClientsModel = initialValues;
    public error = '';
    public message = '';
    public isLoading = false;
    
    constructor() {
        makeAutoObservable(this);
    }

    public init = async (initialValue: ClientsModel | null) => {
        if (initialValue) {
            this.form = initialValue;
        } else {
            this.form = initialValues;
        }
    }

    public createOrEditClient = async (edit?: boolean) => {
        this.isLoading = true;
        const payload = {
            id: this.form.id,
            full_name: this.form.full_name,
            pseudonym: this.form.pseudonym,
            phone: this.form.phone,
            email: this.form.email,
            current_address: this.form.current_address,
            company: this.form.company,
            type_id: this.form.type_id,
            inn: this.form.inn,
            balance: Number(this.form.balance),
        }
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            let res;
            if (edit) {
                res = await axios.put(api.client, payload, {headers});
            } else {
                res = await axios.post(api.client, payload, {headers});
            }
            // if (res?.code >= 400) {
            //     throw new Error(res)
            // }
            this.message = res?.data.message;
            toast.success(this.message);

            return res;
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.isLoading = false;
        }
    }

    public setField = (form: ClientsModel) => {
        this.form = form;
    }

    public destroy = () => {
        this.form = initialValues;
    }

    public get clientTypeIDOptions() {
        return [
            {
                label: 'Физ лицо',
                value: 1,
            },
            {
                label: 'Юр лицо',
                value: 2,
            },
        ]
    }
}

export default new ClientsCreateEditStore();