import {makeAutoObservable} from 'mobx';
import axios, {GenericAbortSignal} from 'axios';
import {api} from '../../api/endpoints';
import AppStore from '../../app.store';
import {ClientsModel, ClientFilterModel} from './clients.model';
import {toast} from 'react-toastify';

const clientsFilterInitialState = {
    search: '',
    role_id: 0,
    business_unit_id: 0,
    active: '',
    page: 1,
    page_limit: 25,
    pages: 0,
    order_by: 'id',
    order_descending: true,
    technical: false,
};

const clientsInitialState: ClientsModel[] = [];
const loadingInitialState: boolean = false;

class ClientsStore {
    public clients?: ClientsModel[] = clientsInitialState;
    public clientsFilter = clientsFilterInitialState;
    public clientsLoading: boolean = loadingInitialState;
    public error = '';
    public message = '';

    public clientsPageInit = async (
        technical: boolean,
        signal: GenericAbortSignal
    ) => {
        void this.clientsReq(technical, signal);
    };

    constructor() {
        makeAutoObservable(this);
    }

    setClients(data: any) {
        const {clients} = data;

        this.clients = clients;
    }

    public clientsReq = async (
        technical?: boolean,
        signal?: GenericAbortSignal
    ) => {
        this.setLoading(true);
        this.clientsFilter.technical = technical!;
        try {
            const {getToken} = AppStore;

            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            };

            const filterUrl = Object.keys(this.clientsFilter).reduce(
                (filters, filter) => {
                    const symbol = filters ? '&' : '?';
                    const filterKeyName = filter as keyof ClientFilterModel;
                    if (
                        this.clientsFilter[filterKeyName] !== 0 &&
                        this.clientsFilter[filterKeyName] !== '' &&
                        this.clientsFilter[filterKeyName] !== undefined
                    ) {
                        filters +=
                            symbol + filter + '=' + this.clientsFilter[filterKeyName];
                    }

                    return filters;
                },
                ''
            );

            const response = await axios.get(api.get_clients + filterUrl, {
                headers,
                signal,
            });

            this.setClients(response.data);
            this.setClientFilterPages(response.data.pages);
        } catch (err) {
        } finally {
            this.setLoading(false);
        }
    };

    public setClientsFilter = ({
                                   search,
                                   role_id,
                                   business_unit_id,
                                   active,
                                   page,
                                   page_limit,
                                   technical,
                               }: ClientFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.clientsFilter = {
            ...this.clientsFilter,
            search: isUndefined(search) ? this.clientsFilter.search! : search!,
            role_id: isUndefined(role_id) ? this.clientsFilter.role_id! : role_id!,
            business_unit_id: isUndefined(business_unit_id)
                ? this.clientsFilter.business_unit_id!
                : business_unit_id!,
            active: isUndefined(active) ? this.clientsFilter.active! : active!,
            page: isUndefined(page) ? this.clientsFilter.page! : page!,
            page_limit: isUndefined(page_limit)
                ? this.clientsFilter.page_limit!
                : page_limit!,
            technical: isUndefined(technical)
                ? this.clientsFilter.technical!
                : technical!,
        };

        if (page_limit) {
            this.clientsFilter.page = 1;
        }

        void this.clientsReq();
    };

    public deleteClient = async (id: number) => {
        this.setLoading(true);
        try {
            const {getToken} = AppStore;
            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            };
            const response = await axios.delete(`${api.client}/${id}`, {headers});
            this.message = response?.data.message;
            toast.success(this.message);

            if (response) {
                this.clientsReq();
            }
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    };

    private setClientFilterPages = (pages: number) => {
        this.clientsFilter.pages = pages;
    };

    private setLoading = (loading: boolean) => {
        this.clientsLoading = loading;
    };

    public clearFilter = () => {
        this.clientsFilter = clientsFilterInitialState;
    };


}

export default new ClientsStore();
