import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {ClientsDebtsModel} from "../../clients.debts/clients.debts.model";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import ClientsDebtsStore from "../../clients.debts/clients.debts.store";
import {renderInputs} from "../../../components/form/render.inputs";
import ClientDebtsPaymentStore from "./client.debts.payment.store";
import {Text} from "../../../styles/text";
import {modalTextColor, primaryColor} from "../../../utils/variables";
import {TextInput} from "../../../styles/form";
import {runInAction} from "mobx";
import {useEffect} from "react";
import {ClientsModel} from "../clients.model";
import ClientsStore from "../clients.store";

interface ClientsDebtsPaymentModalViewProps {
    initialValue: ClientsModel | null;
    setModal: (isOpen: boolean) => void
}

const ClientsDebtsPaymentModalView = observer(({initialValue, setModal}: ClientsDebtsPaymentModalViewProps) => {

    const {
        initClientDebtsPage,
        clientDebts,
        setClientDebtsFilters,
        clearFilter,
    } = ClientsDebtsStore;

    const {clientsPageInit} = ClientsStore

    const {form, setForm, clientDebtPaymentLoading, clientPaymentReq, destroyClientDebtPaymentModal} = ClientDebtsPaymentStore;

    const {amount, details, oper_date_text, total_amount} = form;

    useEffect(() => {
        setClientDebtsFilters({client_id: initialValue?.id, show_unpaid: true})
    }, [setClientDebtsFilters]);

    const handleConfirm = async () => {
        const controller = new AbortController();
        const response = await clientPaymentReq(clientDebts, controller.signal);

        if (response) {
            setModal(false);
            clearFilter();
            destroyClientDebtPaymentModal()
            await clientsPageInit(false, controller.signal);
        }
    }

    const inputs = [
        {field: total_amount, fieldName: 'total_amount', placeholder: 'Сумма', type: 'text', required: true},
        {field: details, fieldName: 'details', placeholder: 'Детали', type: 'text', required: false},
        {field: oper_date_text, fieldName: 'oper_date_text', placeholder: 'Дата операции', type: 'date', required: false},
    ]

    const handleAllocateAmounts = () => {
        let totalAmount = total_amount;

        clientDebts.forEach((debt, _) => {
            if (totalAmount > 0) {
                const allocatedAmount = Math.min(totalAmount, debt.remaining);
                totalAmount -= allocatedAmount;
                runInAction(() => {
                    debt.amount_paid = allocatedAmount;
                })
            }
        })
    }

    const totalPaidAmount = () => {
        let totalAmount = 0;
        clientDebts.reduce((_: any, debt) => {
            debt.amount_paid ? totalAmount += Number(debt.amount_paid!) : totalAmount += 0;
        }, 0)
        return Number(totalAmount.toFixed(2));
    }

    const handleChangeDebtAmount = (index: number, value: any) => {
        let rawValue = value;

        rawValue = rawValue.replace(",", ".");

        const regex = /^(\d+(\.\d{0,2})?|\.|0\.)$/;
        if (regex.test(rawValue) || rawValue === "") {
            clientDebts[index].total_amount = rawValue;
        }
        runInAction(() => {
            clientDebts[index].amount_paid = value;
        })
    }

    const handlePayFullAmountClick = (index: number) => {
        runInAction(() => {
            clientDebts[index].amount_paid = clientDebts[index].remaining;
        })
    }

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setForm)}
            </Layout>
            <Layout centered between={20}>
                <Text color={primaryColor} extent={'M'} weight={'bold'}>Неоплаченные поставки</Text>
                <Layout hAlign={"flex-end"}>
                    <Button
                        onClick={handleAllocateAmounts}
                    >
                        Автооплата
                    </Button>
                </Layout>
            </Layout>

            <Layout vertical between={10}>
                {clientDebts?.map((debt, index) => (
                    <Layout hAlign={"space-between"} vAlign={"center"}
                            style={{
                                borderRadius: '5px',
                                boxShadow: '0 0 5px grey',
                                marginBottom: '5px',
                                padding: '15px',
                            }}>
                        <Layout widthSize={'80%'}>
                            <Layout vertical between={5}>
                                <Layout>
                                    <Text color={primaryColor}>
                                        {debt.owner_tran_id}
                                    </Text>
                                </Layout>
                                <Layout>
                                    <Text>
                                        {`Остаток: ${debt.remaining}`}
                                    </Text>
                                </Layout>
                                <Layout>
                                    <Text>
                                        {debt.created_at_text}
                                    </Text>
                                </Layout>
                            </Layout>
                        </Layout>
                        <Layout vertical between={2}>
                            <Layout>
                                <TextInput
                                    size={20}
                                    extent={'S'}
                                    value={debt.amount_paid ? debt.amount_paid.toString() : ''}
                                    onChange={(e) => handleChangeDebtAmount(index, +e.target.value)}
                                />
                            </Layout>
                            <Layout hAlign={"flex-end"}>
                                <Text
                                    extent={'S'}
                                    onClick={() => handlePayFullAmountClick(index)}
                                    color={primaryColor}
                                    style={{textDecoration: "underline", cursor: 'pointer'}}
                                >
                                    Оплатить полностью
                                </Text>
                            </Layout>
                        </Layout>
                    </Layout>
                ))}
            </Layout>
            <Layout hAlign={"space-between"} vAlign={"center"}>
                <Layout>
                    <Text color={modalTextColor} extent={'S'}>
                        Сумма введена
                    </Text>
                </Layout>
                <Layout>
                    <Text extent={'S'}>
                        {total_amount}
                    </Text>
                </Layout>
            </Layout>
            <Layout hAlign={"space-between"} vAlign={"center"}>
                <Layout>
                    <Text color={modalTextColor} extent={'S'}>
                        Сумма оплачена
                    </Text>
                </Layout>
                <Layout>
                    <Text extent={'S'}>
                        {totalPaidAmount()}
                    </Text>
                </Layout>
            </Layout>
            <Layout hAlign={"space-between"} vAlign={"center"}>
                <Layout>
                    <Text color={modalTextColor} extent={'S'}>
                        Разница
                    </Text>
                </Layout>
                <Layout>
                    <Text
                        color={total_amount < totalPaidAmount() ? 'red' : 'green'}
                        extent={'S'}
                    >
                        {total_amount - totalPaidAmount()}
                    </Text>
                </Layout>
            </Layout>
            <Layout centered>
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={clientDebtPaymentLoading}
                    onClick={handleConfirm}
                    disabled={isValid([inputs]) || total_amount != totalPaidAmount()}
                >{clientDebtPaymentLoading ? '' : 'Потвердить'}</Button>
            </Layout>
        </Layout>
    );
});

export default ClientsDebtsPaymentModalView;