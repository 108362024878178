import {BusinessUnitModel} from "../business_units/business_units.model";
import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {Search, SearchLayout, Select} from "../../styles/table-header";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";
import {ProductCategoryModel} from "../product_categories/product_categories.model";
import {BrandModel} from "../brands/brands.model";
import {TMeasurementsGetResponse} from "./stock.types";
import {Space} from 'antd';
import {TextInput} from "../../styles/form";
import StockStore from "./stock.store";
import React from "react";

interface StocksFilterViewProps {
    handleKeyDown: (e: any) => void;
    cancelSearch: (e: any) => void;
    handleFilterChange: (e: any, key: string, index?: number) => void;
    measureUnits: TMeasurementsGetResponse | undefined;
    categories: ProductCategoryModel[] | undefined;
    brands: BrandModel[] | undefined;
    businessUnits: BusinessUnitModel[] | undefined;
    inModal: boolean;
}

const StocksFiltersView = observer((
    {
        handleKeyDown,
        cancelSearch,
        handleFilterChange,
        measureUnits,
        categories,
        brands,
        businessUnits,
        inModal
    }: StocksFilterViewProps
) => {

    const quantityOptions = [
        {label: 'Больше (>)', value: '>'},
        {label: 'Меньше (<)', value: '<'},
        {label: 'Равно (=)', value: '='},
        {label: 'Не равно (≠)', value: '!='},
        {label: 'Больше или равно (≥)', value: '>='},
        {label: 'Меньше или равно (≤)', value: '<='},
    ];

    const {quantityFilter, setQuantityFilter} = StockStore;

    return (
        <>
            <Layout between={10} wrapped vAlign="center" vertical={inModal}>
                <SearchLayout>
                    <Search
                        type="search"
                        id="search"
                        placeholder="Поиск"
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => cancelSearch(e)}
                    ></Search>
                    <Image src={SearchIcon} height="16px"/>
                </SearchLayout>
                <Select
                    id="measure-unit-filter"
                    onChange={(e) => handleFilterChange(e, "measure_unit_id")}
                >
                    <option value="">Все единицы</option>
                    {measureUnits?.map((measureUnit, _) => (
                        <option value={measureUnit.id} key={measureUnit.id}>
                            {measureUnit.name}
                        </option>
                    ))}
                </Select>
                <Select
                    id="category-filter"
                    onChange={(e) => handleFilterChange(e, "category_id")}
                >
                    <option value="">Все категории</option>
                    {categories?.map((category, _) => (
                        <option value={category.id} key={category.id}>
                            {category.name}
                        </option>
                    ))}
                </Select>
                <Select
                    id="brand-filter"
                    onChange={(e) => handleFilterChange(e, "brand_id")}
                >
                    <option value="">Все бренды</option>
                    {brands?.map((brand, _) => (
                        <option value={brand.id} key={brand.id}>
                            {brand.name}
                        </option>
                    ))}
                </Select>
                <Select
                    id="business_unit-filter"
                    onChange={(e) => handleFilterChange(e, "business_unit_id")}
                >
                    <option value="">Все подразделения</option>
                    {businessUnits?.map((businessUnit, _) => (
                        <option value={businessUnit.id} key={businessUnit.id}>
                            {businessUnit.name}
                        </option>
                    ))}
                </Select>
                <Space.Compact>
                    <Select
                        defaultValue={'>'}
                        onChange={(e) => {
                            setQuantityFilter({symbol: e.target.value})
                            handleFilterChange(`${e.target.value} ${quantityFilter.value}`, 'quantity_in_hand')
                        }}
                        style={{borderTopRightRadius: 'unset', borderBottomRightRadius: 'unset'}}
                    >
                        {quantityOptions.map((option) => (
                            <option value={option.value} key={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
                    <TextInput
                        type={"text"}
                        style={{
                            background: 'white',
                            border: 'none',
                            borderTopLeftRadius: 'unset',
                            borderBottomLeftRadius: 'unset',
                            borderLeft: '0.5px solid',
                            width: '65px'
                        }}
                        placeholder={'Количество'}
                        onChange={(e) => setQuantityFilter({value: +e.target.value})}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleFilterChange(`${quantityFilter.symbol} ${quantityFilter.value}`, 'quantity_in_hand')
                            }
                        }}
                        value={quantityFilter.value}
                    />
                </Space.Compact>
            </Layout>
        </>
    );
});

export default StocksFiltersView;