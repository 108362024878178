import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {ClientsDebtsModel} from "./clients.debts.model";
import {useEffect} from "react";
import ClientsDebtsStore from "./clients.debts.store";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import TableDesktopView from "../../components/table/render.table.desktop";
import TableMobileView from "../../components/table/render.table.mobile";

interface ClientsDebtsOperationsViewProps {
    initialValue: ClientsDebtsModel | null;
}

const ClientsDebtsOperationsView = observer(({initialValue}: ClientsDebtsOperationsViewProps) => {

    const {clientDebtOperations, clientDebtOperationsLoading, getClientDebtOperations} = ClientsDebtsStore;
    const {dictionaryListReq, dictionaryList} = DictionaryStore;


    useEffect(() => {
        const controller = new AbortController();
        void dictionaryListReq('CLIENT_DEBT_OPERATIONS', 'TABLE HEADERS', controller.signal);
        void getClientDebtOperations(initialValue?.id!, controller.signal);

        return () => controller.abort();
    }, [getClientDebtOperations]);
    return (
        <Layout vertical between={20}>
            <TableDesktopView data={clientDebtOperations} headers={dictionaryList} loading={clientDebtOperationsLoading}/>
            <TableMobileView data={clientDebtOperations} headers={dictionaryList} loading={clientDebtOperationsLoading}/>
        </Layout>
    );
});

export default ClientsDebtsOperationsView;