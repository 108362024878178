import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {SuppliersModel} from "../suppliers.model";
import {isValid} from "../../../utils/isValid";
import {Button} from "../../../styles/button";
import SuppliersPaymentModalStore from "./suppliers.payment.modal.store";
import {renderInputs} from "../../../components/form/render.inputs";
import PurchaseBillsStore from "../../purchase.bills/purchase.bills.store";
import {useEffect} from "react";
import {Text} from "../../../styles/text";
import {modalTextColor, primaryColor} from "../../../utils/variables";
import {TextInput} from "../../../styles/form";
import {runInAction} from "mobx";
import BusinessUnitsStore from "../../business_units/business_units.store";

interface SuppliersPaymentModalViewProps {
    initialValue: SuppliersModel | null;
    setModal: (isOpen: boolean) => void;
}

const SuppliersPaymentModalView = observer(({initialValue, setModal}: SuppliersPaymentModalViewProps) => {

    const {
        isLoading,
        destroySupplierPaymentModal,
        form,
        setForm,
        supplierPaymentReq
    } = SuppliersPaymentModalStore;
    const {businessUnits, setBusinessUnitFilter} = BusinessUnitsStore

    const {setBillsFilter, bills} = PurchaseBillsStore;

    useEffect(() => {
        setBusinessUnitFilter({page_limit: 1000000, return_balance: false})
        setBillsFilter({
            supplier_id: initialValue?.id,
            page: 1,
            page_limit: 100000,
            show_unpaid: 'true',
            order_by: 'id',
            order_descending: false
        })
    }, [setBillsFilter]);

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    )

    const {from_bu_id, total_amount, details, oper_date, show_unpaid_bills} = form

    const inputs = [
        {
            field: from_bu_id,
            fieldName: 'from_bu_id',
            placeholder: 'Касса',
            type: 'select',
            required: false,
            options: businessUnitsOptions
        },
        {
            field: total_amount,
            fieldName: 'total_amount',
            placeholder: 'Сумма',
            type: 'number',
            required: true
        },
        {
            field: oper_date,
            fieldName: 'oper_date',
            placeholder: 'Дата оплаты',
            type: 'date',
            required: false
        },
        {
            field: details,
            fieldName: 'details',
            placeholder: 'Детали',
            type: 'text',
            required: false
        },
        {
            field: show_unpaid_bills,
            fieldName: 'show_unpaid_bills',
            placeholder: 'Показать неоплаченные поставки',
            type: 'switch',
            required: false
        },
    ]

    const handleSubmit = async () => {
        const resp = await supplierPaymentReq(bills, initialValue?.id!);

        if (resp) {
            setModal(false);
            destroySupplierPaymentModal();
        }
    }

    const handleCancelClick = () => {
        destroySupplierPaymentModal();
        setModal(false);
    }

    const handleChangeBillAmount = (index: number, value: any) => {
        runInAction(() => {
            bills[index].amount_paid = value;
        })
    }

    const handlePayFullAmountClick = (index: number) => {
        runInAction(() => {
            bills[index].amount_paid = bills[index].remaining;
        })
    }

    const totalPaidAmount = () => {
        let totalAmount: number = 0;
        bills.reduce((_: any, bill) => {
            totalAmount += Number(bill.amount_paid!) | 0;
        }, 0)
        return Number(totalAmount.toFixed(2));
    }

    const handleAllocateAmounts = () => {
        let totalAmount = total_amount;

        bills.forEach((bill, _) => {
            if (totalAmount > 0) {
                const allocatedAmount = Math.min(totalAmount, bill.remaining);
                totalAmount -= allocatedAmount;
                runInAction(() => {
                    bill.amount_paid = allocatedAmount;
                })
            }
        })
    }

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setForm)}
            </Layout>
            {show_unpaid_bills && (
                <>
                    <Layout centered between={20}>
                        <Text color={primaryColor} extent={'M'} weight={'bold'}>Неоплаченные поставки</Text>
                        <Layout hAlign={"flex-end"}>
                            <Button
                                onClick={handleAllocateAmounts}
                            >
                                Автооплата
                            </Button>
                        </Layout>
                    </Layout>

                    <Layout vertical between={10}>
                        {bills?.map((bill, index) => (
                            <Layout hAlign={"space-between"} vAlign={"center"}
                                    style={{
                                        borderRadius: '5px',
                                        boxShadow: '0 0 5px grey',
                                        marginBottom: '5px',
                                        padding: '15px',
                                    }}>
                                <Layout widthSize={'80%'}>
                                    <Layout vertical between={5}>
                                        <Layout>
                                            <Text color={primaryColor}>
                                                {bill.document_number}
                                            </Text>
                                        </Layout>
                                        <Layout>
                                            <Text>
                                                {`Остаток: ${bill.remaining}`}
                                            </Text>
                                        </Layout>
                                        <Layout>
                                            <Text>
                                                {bill.document_date_text}
                                            </Text>
                                        </Layout>
                                    </Layout>
                                </Layout>
                                <Layout vertical between={2}>
                                    <Layout>
                                        <TextInput
                                            size={20}
                                            extent={'S'}
                                            value={bill.amount_paid! | 0}
                                            onChange={(e) => handleChangeBillAmount(index, +e.target.value)}
                                        />
                                    </Layout>
                                    <Layout hAlign={"flex-end"}>
                                        <Text
                                            extent={'S'}
                                            onClick={() => handlePayFullAmountClick(index)}
                                            color={primaryColor}
                                            style={{textDecoration: "underline", cursor: 'pointer'}}
                                        >
                                            Оплатить полностью
                                        </Text>
                                    </Layout>
                                </Layout>
                            </Layout>
                        ))}
                    </Layout>
                    <Layout hAlign={"space-between"} vAlign={"center"}>
                        <Layout>
                            <Text color={modalTextColor} extent={'S'}>
                                Сумма введена
                            </Text>
                        </Layout>
                        <Layout>
                            <Text extent={'S'}>
                                {total_amount}
                            </Text>
                        </Layout>
                    </Layout>
                    <Layout hAlign={"space-between"} vAlign={"center"}>
                        <Layout>
                            <Text color={modalTextColor} extent={'S'}>
                                Сумма оплачена
                            </Text>
                        </Layout>
                        <Layout>
                            <Text extent={'S'}>
                                {totalPaidAmount()}
                            </Text>
                        </Layout>
                    </Layout>
                    <Layout hAlign={"space-between"} vAlign={"center"}>
                        <Layout>
                            <Text color={modalTextColor} extent={'S'}>
                                Разница
                            </Text>
                        </Layout>
                        <Layout>
                            <Text
                                color={total_amount < totalPaidAmount() ? 'red' : 'green'}
                                extent={'S'}
                            >
                                {total_amount - totalPaidAmount()}
                            </Text>
                        </Layout>
                    </Layout>
                </>
            )}
            <Layout centered>
                <Layout between={20}>
                    <Button
                        extent="S"
                        heightSize="35px"
                        onClick={handleCancelClick}
                        background={'#888'}
                    >
                        Отменить
                    </Button>
                    <Button
                        extent="S"
                        heightSize="35px"
                        isLoading={isLoading}
                        onClick={handleSubmit}
                        disabled={isValid([inputs]) || (show_unpaid_bills && total_amount !== totalPaidAmount())}
                    >
                        {isLoading ? '' : 'Потвердить'}
                    </Button>
                </Layout>
            </Layout>
        </Layout>
    );
});

export default SuppliersPaymentModalView;