export const api = {
  get_counted_bus: `${process.env.REACT_APP_API}/count_bus`,
  get_top_10_products: `${process.env.REACT_APP_API}/top_10_products`,
  get_daily_worker_graph: `${process.env.REACT_APP_API}/daily_worker_graph`,
  login: `${process.env.REACT_APP_API}/login`,
  get_users: `${process.env.REACT_APP_API}/users`,
  user: `${process.env.REACT_APP_API}/user`,
  client: `${process.env.REACT_APP_API}/clients`,
  owner: `${process.env.REACT_APP_API}/owner`,
  get_clients: `${process.env.REACT_APP_API}/clients`,
  get_me: `${process.env.REACT_APP_API}/get_me`,
  get_roles: `${process.env.REACT_APP_API}/roles`,
  role: `${process.env.REACT_APP_API}/role`,
  role_with_accesses: `${process.env.REACT_APP_API}/role_with_accesses`,
  access_groups: `${process.env.REACT_APP_API}/access_groups`,
  accesses: `${process.env.REACT_APP_API}/accesses`,
  transactions: `${process.env.REACT_APP_API}/transaction`,
  transactionsExtended: `${process.env.REACT_APP_API}/transaction_extended`,
  transaction_types: `${process.env.REACT_APP_API}/transaction_types`,
  transactions_excel: `${process.env.REACT_APP_API}/transactions_excel`,
  transaction_statuses: `${process.env.REACT_APP_API}/transaction_statuses`,
  transaction_approve: `${process.env.REACT_APP_API}/approve_transaction`,
  transaction_cancel: `${process.env.REACT_APP_API}/cancel_transaction`,
  transaction_refund: `${process.env.REACT_APP_API}/refund_transaction`,
  get_business_units: `${process.env.REACT_APP_API}/business_units`,
  business_unit: `${process.env.REACT_APP_API}/business_unit`,
  business_service: `${process.env.REACT_APP_API}/business_services`,
  business_stocks: `${process.env.REACT_APP_API}/business_stocks`,
  business_stock: `${process.env.REACT_APP_API}/business_stock`,
  product: `${process.env.REACT_APP_API}/product`,
  get_products: `${process.env.REACT_APP_API}/products`,
  product_types: `${process.env.REACT_APP_API}/product_types`,
  product_brands: `${process.env.REACT_APP_API}/product_brands`,
  product_categories: `${process.env.REACT_APP_API}/product_categories`,
  get_measurements: `${process.env.REACT_APP_API}/measure_units`,
  get_business_goods: `${process.env.REACT_APP_API}/business_goods`,
  get_workers_general_report: `${process.env.REACT_APP_API}/workers_general_report`,
  get_bu_general_report: `${process.env.REACT_APP_API}/bu_general_report`,
  get_profit_report: `${process.env.REACT_APP_API}/profit_report`,
  get_daily_worker_report: `${process.env.REACT_APP_API}/daily_worker_report`,
  download_transaction_cheque: `${process.env.REACT_APP_API}/transaction_cheque_pdf`,
  get_notifications: `${process.env.REACT_APP_API}/notifications?limit=10`,
  put_notifications: `${process.env.REACT_APP_API}/read_notifications`,
  register_notification_token: `${process.env.REACT_APP_API}/fcm_token`,
  account: `${process.env.REACT_APP_API}/account`,
  account_categories: `${process.env.REACT_APP_API}/account_categories`,
  profile: `${process.env.REACT_APP_API}/user/profile`,
  change_password: `${process.env.REACT_APP_API}/users/password`,
  transaction_products: `${process.env.REACT_APP_API}/transaction_products`,
  payment_methods: `${process.env.REACT_APP_API}/payment_methods`,
  get_countries: `${process.env.REACT_APP_API}/countries`,
  product_category: `${process.env.REACT_APP_API}/product_category`,
  brands: `${process.env.REACT_APP_API}/product_brand`,
  transaction_journal: `${process.env.REACT_APP_API}/transaction_journal`,
  account_records: `${process.env.REACT_APP_API}/account_records`,
  transactions_service_accounts: `${process.env.REACT_APP_API}/transactions_service_accounts`,
  suppliers: `${process.env.REACT_APP_API}/suppliers`,
  client_contacts: `${process.env.REACT_APP_API}/clients/contacts`,
  transfers: `${process.env.REACT_APP_API}/transfers`,
  statuses: `${process.env.REACT_APP_API}/statuses`,
  payrolls: `${process.env.REACT_APP_API}/payrolls`,
  expenses: `${process.env.REACT_APP_API}/expenses`,
  dictionaries: `${process.env.REACT_APP_API}/dictionaries`,
  main: `${process.env.REACT_APP_API}/main_page`,
  sales_reports: `${process.env.REACT_APP_API}/sales_reports`,
  fin_reports: `${process.env.REACT_APP_API}/financial_report`,
  cards: `${process.env.REACT_APP_API}/clientCards`,
  client_documents: `${process.env.REACT_APP_API}/clients/doc`,
  product_serial_numbest: `${process.env.REACT_APP_API}/product/serial`,
  status_roles: `${process.env.REACT_APP_API}/transaction_statuses2roles`,
  goods_movement: `${process.env.REACT_APP_API}/product/movement`,
  tracking_history: `${process.env.REACT_APP_API}/product/movement`,
  shipments: `${process.env.REACT_APP_API}/shipments`,
  purchase_bills: `${process.env.REACT_APP_API}/purchase_bills`,
  track_movement: `${process.env.REACT_APP_API}/product/track_movement`,
  transfer_funds: `${process.env.REACT_APP_API}/transfer_funds`,
  client_debts: `${process.env.REACT_APP_API}/client_debts`,
  client_debt_operations: `${process.env.REACT_APP_API}/client_debt_operations`,
  cancel_client_debt_operations: `${process.env.REACT_APP_API}/cancel_client_debt_operations`,
  pay_client_debt: `${process.env.REACT_APP_API}/pay_client_debt`,
  supplier_transactions: `${process.env.REACT_APP_API}/supplier_transactions`,
};
