import {makeAutoObservable} from "mobx";
import {ClientsDebtOperationsFilterModel, ClientsDebtsOperationsModel} from "./clients.debts.operations.model";
import axios from "axios";
import {api} from "../../api/endpoints";
import AppStore from "../../app.store";
import {toast} from "react-toastify";

const loadingInitialState: boolean = false;
const operationsInitialState: ClientsDebtsOperationsModel[] = [];
const filtersInitialState: ClientsDebtOperationsFilterModel = {
    business_unit_id: 0,
    user_id: 0,
    client_id: 0,
    amount_from: 0,
    amount_to: 0,
    status: 0, //1 - not paid 2 - partially 3 - fully paid
    date_from: '',
    date_to: '',
    search: '',
    order_by: '',
    order_descending: false,
    page: 1,
    page_limit: 25,
    pages: 0,
}

const cancelOperationFormInitialValue = {
    amount: 0,
    details: '',
    oper_date_text: '',
}

const {getToken} = AppStore;
const headers = {
    Authorization: `Bearer ${getToken()!}`,
}

class ClientsDebtsOperationsStore {
    public clientDebtsOperations = operationsInitialState;
    public clientDebtOperationsLoading = loadingInitialState;
    public clientDebtOperationsFilters = filtersInitialState;
    public cancelOperationLoading = loadingInitialState;
    public cancelOperationForm = cancelOperationFormInitialValue;
    error: string = '';
    message: string = '';


    constructor() {
        makeAutoObservable(this)
    }

    public setForm = (form: any) => {
        this.cancelOperationForm = form
    }

    public initClientDebtOperationsPage = async (signal: AbortSignal) => {
        await this.getClientDebtOperations(signal)
    }

    public destroyCancelForm = () => {
        this.cancelOperationForm = cancelOperationFormInitialValue
    }

    setCancelLoading(loading: boolean) {
        this.cancelOperationLoading = loading;
    }

    setClientDebtOperations(data: any) {
        const {debt_operations} = data;
        this.clientDebtsOperations = debt_operations;
    }

    setClientDebtOperationsLoading(loading: boolean) {
        this.clientDebtOperationsLoading = loading;
    }

    setClientDebtPages(pages: number) {
        this.clientDebtOperationsFilters.pages = pages;
    }

    public setClientDebtOperationsFilters = ({
                                        business_unit_id,
                                        user_id,
                                        client_id,
                                        amount_from,
                                        amount_to,
                                        status,
                                        date_from,
                                        date_to,
                                        search,
                                        page,
                                        page_limit
                                    }: ClientsDebtOperationsFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.clientDebtOperationsFilters = {
            ...this.clientDebtOperationsFilters,
            business_unit_id: isUndefined(business_unit_id) ? this.clientDebtOperationsFilters.business_unit_id! : business_unit_id!,
            user_id: isUndefined(user_id) ? this.clientDebtOperationsFilters.user_id! : user_id!,
            client_id: isUndefined(client_id) ? this.clientDebtOperationsFilters.client_id! : client_id!,
            amount_from: isUndefined(amount_from) ? this.clientDebtOperationsFilters.amount_from! : amount_from!,
            amount_to: isUndefined(amount_to) ? this.clientDebtOperationsFilters.amount_to! : amount_to!,
            status: isUndefined(status) ? this.clientDebtOperationsFilters.status! : status!,
            date_from: isUndefined(date_from) ? this.clientDebtOperationsFilters.date_from! : date_from!,
            date_to: isUndefined(date_to) ? this.clientDebtOperationsFilters.date_to! : date_to!,
            search: isUndefined(search) ? this.clientDebtOperationsFilters.search! : search!,
            page: isUndefined(page) ? this.clientDebtOperationsFilters.page! : page!,
            page_limit: isUndefined(page_limit) ? this.clientDebtOperationsFilters.page_limit! : page_limit!,

        }

        void this.getClientDebtOperations();
    }

    public getClientDebtOperations = async (signal?: AbortSignal) => {
        this.setClientDebtOperationsLoading(true);

        const filterUrl = Object.keys(this.clientDebtOperationsFilters).reduce(
            (filters, filter) => {
                const symbol = filters ? '&' : '?';
                const filterKeyName = filter as keyof ClientsDebtOperationsFilterModel;
                if (
                    this.clientDebtOperationsFilters[filterKeyName] !== 0 &&
                    this.clientDebtOperationsFilters[filterKeyName] !== '' &&
                    this.clientDebtOperationsFilters[filterKeyName] !== undefined
                ) {
                    filters += symbol + filter + '=' + this.clientDebtOperationsFilters[filterKeyName];
                }

                return filters;
            }, '');

        try {
            const response = await axios.get(api.client_debt_operations + filterUrl, {headers, signal})
            this.setClientDebtOperations(response?.data);
            this.setClientDebtPages(response?.data.pages);
        } catch (e: any) {

        } finally {
            this.setClientDebtOperationsLoading(false)
        }
    }

    public cancelPaymentReq = async (operationID: number, debtID: number, signal?: AbortSignal) => {
        this.setCancelLoading(true);

        const payload = {
            operation_id: operationID,
            debt_id: debtID,
            amount: Number(this.cancelOperationForm.amount),
            details: this.cancelOperationForm.details,
            oper_date_text: this.cancelOperationForm.oper_date_text
        }

        try {
            const response = await axios.put(api.cancel_client_debt_operations, payload, {headers, signal});
            this.message = response?.data.message
            toast.success(this.message);
            return response
        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error);
        } finally {
            this.setCancelLoading(false);
        }
    }
}

export default new ClientsDebtsOperationsStore();