import AppStore from "../../../app.store";
import {makeAutoObservable} from "mobx";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {ClientsDebtsModel} from "../../clients.debts/clients.debts.model";

interface Form {
    id: number;
    amount: number;
    details: string;
    oper_date_text: string;
    total_amount: number;
}

const loadingInitialState: boolean = false;
const {getToken} = AppStore;
const headers = {
    Authorization: `Bearer ${getToken()!}`
}

const paymentFormInitialValue: Form = {
    id: 0,
    amount: 0,
    details: '',
    oper_date_text: '',
    total_amount: 0,
}

class ClientDebtsPaymentStore {
    public clientDebtPaymentLoading = loadingInitialState;
    public form = paymentFormInitialValue;
    public error: string = '';
    public message: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public destroyClientDebtPaymentModal = () => {
        this.clientDebtPaymentLoading = loadingInitialState;
        this.form = paymentFormInitialValue;
        this.message = '';
        this.error = '';
    }

    setLoading(loading: boolean) {
        this.clientDebtPaymentLoading = loading;
    }

    public setForm = (form: any) => {
        this.form = form;
    }

    public clientPaymentReq = async (clientDebts: ClientsDebtsModel[], signal: AbortSignal) => {
        this.setLoading(true)

        const payload = clientDebts.filter((debt) => Number(debt.amount_paid) > 0).map((debt) => ({
            debt_id: debt.id,
            amount: Number(debt.amount_paid),
            oper_date_text: this.form.oper_date_text,
            details: this.form.details,
        }));

        try {
            const response = await axios.post(api.pay_client_debt, payload, {headers, signal});
            this.message = response?.data.message;
            toast.success(this.message);
            return response
        } catch (e: any) {
            this.error = e?.response?.data?.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new ClientDebtsPaymentStore();