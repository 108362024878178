import {observer} from 'mobx-react';
import {Page} from '../../styles/page';
import AppStore from '../../app.store';
import {useEffect, useState} from 'react';
import SuppliersStore from './suppliers.store';
import {SuppliersModel} from './suppliers.model';
import ConfirmDelete, {
    DeleteItem,
} from '../../components/confirm/confirm.delete';
import {Headline} from '../../styles/headline';
import {primaryColor} from '../../utils/variables';
import {AddButton, TableHeader} from '../../styles/table-header';
import {Filters} from '../../styles/filters';
import {Layout} from '../../styles/layout';
import {Image} from '../../styles/image';
import {
    ContextMenu,
    ContextMenuItem,
    Table,
    TableBody,
    TableContainer,
    TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import ContextMenuIcon from '../../assets/icons/context-menu.svg';
import Modal from '../../components/modal/modal.view';
import SupplierCreateEditView from './suppliers.modal/suppliers.create.edit.view';
import SupplierBrandsView from './supplier.brands.modal/supplier.brands.view';
import AccountModalView from '../../components/modal/account.modal.view';
import SuppliersTableMobileView from './suppliers.mobile.view';
import TablePaginationView from '../../utils/table.pagination.view';
import {Button, FilterButton} from '../../styles/button';
import SuppliersFiltersView from './suppliers.filters.view';
import AccountView from '../accounts/account.view';
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import SuppliersPaymentModalView from "./suppliers.payment.modal/suppliers.payment.modal.view";
import SuppliersPaymentModalStore from "./suppliers.payment.modal/suppliers.payment.modal.store";

const SuppliersView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        suppliers,
        loading,
        suppliersFilter,
        suppliersInit,
        setSuppliersFilter,
        deleteSupplier,
        countries,
        clearFilter,
    } = SuppliersStore;
    const {destroySupplierPaymentModal} = SuppliersPaymentModalStore;
    const [anchorEl, setAnchorEl] = useState(null);
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<SuppliersModel | null>(null);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
        isModalOpen: false,
    });
    const [brandsModal, setBrandsModal] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [accountModal, setAccountModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;
    const [paymentModal, setPaymentModal] = useState(false);

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        void dictionaryListReq('SUPPLIERS', 'TABLE HEADERS', controller.signal);
        suppliersInit(controller.signal);

        return () => {
            controller.abort();
        };
    }, [destroyDictionary, dictionaryListReq, suppliersInit]);

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setSuppliersFilter({page: index + 1});
            return;
        }
        setSuppliersFilter({[objectKey]: options[selectedIndex].value});
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && suppliersFilter.search !== '') {
            setSuppliersFilter({search: ''});
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setSuppliersFilter({search: e.target.value});
        }
    };

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const handleEditClick = (supplier: SuppliersModel) => {
        setModal(true);
        setInitialValue(supplier);
    };

    const handleDelete = async () => {
        await deleteSupplier(confirmDeleteModal.id!);
        setConfirmDeleteModal({isModalOpen: false});
    };

    const prevPage = () => {
        if (suppliersFilter.page! > 1) {
            setSuppliersFilter({page: suppliersFilter.page! - 1});
        }
    };

    const nextPage = () => {
        if (suppliersFilter.page! < suppliersFilter.pages!) {
            setSuppliersFilter({page: suppliersFilter.page! + 1});
        }
    };

    const handleBrandsModalClick = (supplier: SuppliersModel) => {
        setBrandsModal(true);
        setInitialValue(supplier);
    };

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    };

    const handleConfirmFilter = () => {
        setFilterModal(false);
    };

    const handleAccountClick = (supplier: SuppliersModel) => {
        setAccountModal(true);
        setInitialValue(supplier);
    };

    const handlePaymentClick = (supplier: SuppliersModel) => {
        setInitialValue(supplier);
        setPaymentModal(true);
    }

    const handleClosePaymentModal = () => {
        destroySupplierPaymentModal();
        setInitialValue(null);
        setPaymentModal(false);
    }

    const pageLimits = [25, 50, 100, 250, 500];

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <>
                <Headline color={primaryColor}> {getPageTitle('SUPPLIERS')} </Headline>
                <TableHeader>
                    <Filters hAlign="space-between" wrapped between={10}>
                        <SuppliersFiltersView
                            handleKeyDown={handleKeyDown}
                            cancelSearch={cancelSearch}
                            handleFilterChange={handleFilterChange}
                            countries={countries}
                            inModal={false}
                        />
                    </Filters>
                    <FilterButton onClick={() => setFilterModal(true)}>
                        Фильтры
                    </FilterButton>
                    <AddButton
                        type="button"
                        id="add-supplier"
                        onClick={() => setModal(true)}
                    >
                        Создать
                    </AddButton>
                </TableHeader>
                <TableContainer isMacOS={AppStore.isMacOS}>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>{getTableHeaders('name')}</THeadCell>
                                <THeadCell>{getTableHeaders('phone')}</THeadCell>
                                <THeadCell>{getTableHeaders('contact_name')}</THeadCell>
                                <THeadCell>{getTableHeaders('country')}</THeadCell>
                                <THeadCell>Баланс</THeadCell>
                                <THeadCell></THeadCell>
                            </TRow>
                        </TableHead>
                        {loading ? (
                            <TableSkeleton columns={6}/>
                        ) : (
                            <TableBody>
                                {suppliers?.length ? (
                                    suppliers?.map((supplier, index) => (
                                        <TRow key={index} menu>
                                            <TCell>{supplier.name}</TCell>
                                            <TCell>{supplier.phone}</TCell>
                                            <TCell>{supplier.contact_name}</TCell>
                                            <TCell>{supplier.country}</TCell>
                                            <TCell>{supplier.balance}</TCell>
                                            <TCell relative>
                                                <Image
                                                    src={ContextMenuIcon}
                                                    height="18px"
                                                    onClick={handleContext}
                                                />
                                                <ContextMenu anchorEl={anchorEl}>
                                                    <ContextMenuItem
                                                        onClick={() => handlePaymentClick(supplier)}
                                                    >
                                                        Провести оплату
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() => handleBrandsModalClick(supplier)}
                                                    >
                                                        Бренды
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() => handleEditClick(supplier)}
                                                    >
                                                        Изменить
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() =>
                                                            setConfirmDeleteModal({
                                                                isModalOpen: true,
                                                                id: supplier.id,
                                                                name: supplier.name,
                                                            })
                                                        }
                                                    >
                                                        Удалить
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() => handleAccountClick(supplier)}
                                                    >
                                                        Счета
                                                    </ContextMenuItem>
                                                </ContextMenu>
                                            </TCell>
                                        </TRow>
                                    ))
                                ) : (
                                    <NoResultTitle colspan={6}/>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TableContainerMobile>
                    <SuppliersTableMobileView
                        loading={loading}
                        suppliers={suppliers}
                        handleEditClick={handleEditClick}
                        setConfirmDeleteModal={setConfirmDeleteModal}
                        handleBrandsModalClick={handleBrandsModalClick}
                        tableHeaders={getTableHeaders}
                    />
                </TableContainerMobile>
                <TablePaginationView
                    prevPage={prevPage}
                    nextPage={nextPage}
                    pages={suppliersFilter.pages}
                    currentPage={suppliersFilter.page!}
                    pageLimits={pageLimits}
                    handleFilterChange={handleFilterChange}
                />
            </>
            {filterModal && (
                <Modal title={'Фильтры'} onClose={() => setFilterModal(false)}>
                    <Layout vertical between={10}>
                        <SuppliersFiltersView
                            handleKeyDown={handleKeyDown}
                            cancelSearch={cancelSearch}
                            handleFilterChange={handleFilterChange}
                            countries={countries}
                            inModal={true}
                        />
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={handleConfirmFilter}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>
                </Modal>
            )}
            {modal && (
                <Modal
                    title={initialValue ? 'Изменить поставщика' : 'Создать поставщика'}
                    onClose={() => {
                        setModal(false);
                        setInitialValue(null);
                    }}
                >
                    <SupplierCreateEditView
                        initialValue={initialValue}
                        setModal={setModal}
                    />
                </Modal>
            )}
            {confirmDeleteModal.isModalOpen && (
                <Modal
                    title="Удалить поставщика"
                    onClose={() => {
                        setConfirmDeleteModal({isModalOpen: false});
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить поставщика ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={loading}
                    />
                </Modal>
            )}
            {brandsModal && (
                <AccountModalView
                    title={`Бренды поставщика ${initialValue?.name!}`}
                    onClose={() => setBrandsModal(false)}
                >
                    <SupplierBrandsView initialValue={initialValue}/>
                </AccountModalView>
            )}
            {accountModal && (
                <AccountModalView
                    title={`Счета поставщика ${initialValue?.name}`}
                    onClose={() => setAccountModal(false)}
                    hasContext
                >
                    <AccountView
                        entityType={'supplier'}
                        entityId={`${initialValue?.id}`}
                    />
                </AccountModalView>
            )}
            {paymentModal && (
                <Modal
                    title={`Провести оплату поставщика ${initialValue?.name}`}
                    onClose={handleClosePaymentModal}
                >
                   <SuppliersPaymentModalView initialValue={initialValue} setModal={setPaymentModal}/>
                </Modal>
            )}
        </Page>
    );
});

export default SuppliersView;
