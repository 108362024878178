import {ClientsDebtsFilterModel, ClientsDebtsModel, ClientDebtOperationsModel} from "./clients.debts.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";
import {toast} from "react-toastify";

const {getToken} = AppStore;
const headers = {
    Authorization: `Bearer ${getToken()!}`
}

const clientDebtsInitialState: ClientsDebtsModel[] = [];
const clientDebtOperationsInitialState: ClientDebtOperationsModel[] = [];
const loadingInitialState: boolean = false;
const clientDebtsFiltersInitialState: ClientsDebtsFilterModel = {
    business_unit_id: 0,
    user_id: 0,
    client_id: 0,
    amount_from: 0,
    amount_to: 0,
    status: 0, //1 - not paid 2 - partially 3 - fully paid
    date_from: '',
    date_to: '',
    search: '',
    order_by: '',
    order_descending: false,
    page: 1,
    page_limit: 25,
    pages: 0,
}

const paymentFormInitialValue = {
    amount: 0,
    details: '',
    oper_date_text: '',
}

class ClientsDebtsStore {
    public clientDebts = clientDebtsInitialState;
    public clientDebtsLoading = loadingInitialState;
    public clientDebtsFilters = clientDebtsFiltersInitialState;
    public clientDebtOperations = clientDebtOperationsInitialState;
    public clientDebtOperationsLoading = loadingInitialState;
    public paymentForm = paymentFormInitialValue;
    public paymentLoading = loadingInitialState;
    error: string = '';
    message: string = '';

    constructor() {
        makeAutoObservable(this)
    }

    public setPaymentForm = (form: any) => {
        this.paymentForm = form;
    }

    public initClientDebtsPage = async (signal: AbortSignal) => {
        await this.getClientDebtReq(signal)
    }

    public destroyPaymentForm = () => {
        this.paymentForm = paymentFormInitialValue
    }

    setPaymentLoading(loading: boolean) {
        this.paymentLoading = loading;
    }

    setLoading(loading: boolean) {
        this.clientDebtsLoading = loading
    }

    setClientDebts(data: any) {
        const {debts} = data;
        this.clientDebts = debts;
    }

    setClientDebtOperations(data: any) {
        const {debt_operations} = data;
        this.clientDebtOperations = debt_operations;
    }

    setClientDebtOperationsLoading(loading: boolean) {
        this.clientDebtOperationsLoading = loading;
    }

    setFilterPages(pages: number) {
        this.clientDebtsFilters.pages = pages
    }

    public getClientDebtReq = async (signal?: AbortSignal) => {

        this.setLoading(true)

        const filterUrl = Object.keys(this.clientDebtsFilters).reduce(
            (filters, filter) => {
                const symbol = filters ? '&' : '?';
                const filterKeyName = filter as keyof ClientsDebtsFilterModel;
                if (
                    this.clientDebtsFilters[filterKeyName] !== 0 &&
                    this.clientDebtsFilters[filterKeyName] !== '' &&
                    this.clientDebtsFilters[filterKeyName] !== undefined
                ) {
                    filters += symbol + filter + '=' + this.clientDebtsFilters[filterKeyName];
                }

                return filters;
            }, '');

        try {
            const response = await axios.get(api.client_debts + filterUrl, {headers, signal});
            this.setFilterPages(response?.data.pages);
            this.setClientDebts(response?.data);
        } catch (e: any) {

        } finally {
            this.setLoading(false)
        }
    }

    public setClientDebtsFilters = ({
                                        business_unit_id,
                                        user_id,
                                        client_id,
                                        amount_from,
                                        amount_to,
                                        status,
                                        date_from,
                                        date_to,
                                        search,
                                        page,
                                        page_limit,
                                        show_unpaid,
                                    }: ClientsDebtsFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.clientDebtsFilters = {
            ...this.clientDebtsFilters,
            business_unit_id: isUndefined(business_unit_id) ? this.clientDebtsFilters.business_unit_id! : business_unit_id!,
            user_id: isUndefined(user_id) ? this.clientDebtsFilters.user_id! : user_id!,
            client_id: isUndefined(client_id) ? this.clientDebtsFilters.client_id! : client_id!,
            amount_from: isUndefined(amount_from) ? this.clientDebtsFilters.amount_from! : amount_from!,
            amount_to: isUndefined(amount_to) ? this.clientDebtsFilters.amount_to! : amount_to!,
            status: isUndefined(status) ? this.clientDebtsFilters.status! : status!,
            date_from: isUndefined(date_from) ? this.clientDebtsFilters.date_from! : date_from!,
            date_to: isUndefined(date_to) ? this.clientDebtsFilters.date_to! : date_to!,
            search: isUndefined(search) ? this.clientDebtsFilters.search! : search!,
            page: isUndefined(page) ? this.clientDebtsFilters.page! : page!,
            page_limit: isUndefined(page_limit) ? this.clientDebtsFilters.page_limit! : page_limit!,
            show_unpaid: isUndefined(show_unpaid) ? this.clientDebtsFilters.show_unpaid! : show_unpaid!,

        }

        void this.getClientDebtReq();
    }

    public getClientDebtOperations = async (debtID: number, signal?: AbortSignal) => {
        this.setClientDebtOperationsLoading(true);

        try {
            const response = await axios.get(api.client_debt_operations + `?debt_id=${debtID}`, {headers, signal})
            this.setClientDebtOperations(response?.data);
        } catch (e: any) {

        } finally {
            this.setClientDebtOperationsLoading(false)
        }
    }

    public clearFilter = () => {
        this.clientDebtsFilters = clientDebtsFiltersInitialState;
    }

    public payClientDebt = async (debtID: number, signal: AbortSignal) => {
        this.setPaymentLoading(true);

        const payload = {
            debt_id: debtID,
            amount: Number(this.paymentForm.amount),
            details: this.paymentForm.details,
            oper_date_text: this.paymentForm.oper_date_text,
        }

        try {
            const response = await axios.post(api.pay_client_debt, payload, {headers, signal});
            this.message = response?.data.message;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error);
        } finally {
            this.setPaymentLoading(false)
        }
    }
}

export default new ClientsDebtsStore();