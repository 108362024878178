import {observer} from "mobx-react";
import {TableHeader} from "../../styles/table-header";
import {Page} from "../../styles/page";
import AppStore from "../../app.store";
import {Headline} from "../../styles/headline";
import {primaryColor} from "../../utils/variables";
import PurchaseBillsOperationsStore from "./purchase.bills.operations.store";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import {useEffect, useState} from "react";
import SuppliersStore from "../suppliers/suppliers.store";
import BusinessUnitsStore from "../business_units/business_units.store";
import ProductsStore from "../products/products.store";
import UsersStore from "../users/users.store";
import {Filters} from "../../styles/filters";
import FilterView from "../../components/filters/filters.view";
import {FilterButton} from "../../styles/button";
import TableDesktopView from "../../components/table/render.table.desktop";
import TableMobileView from "../../components/table/render.table.mobile";
import TablePaginationView from "../../utils/table.pagination.view";
import ModalView from "../../components/modal/modal.view";

const PurchaseBillsOperationsView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        operations,
        operationsFilter,
        setOperationsFilters,
        initBillsOperations,
        loading
    } = PurchaseBillsOperationsStore;
    const {destroyDictionary, dictionaryListReq, getPageTitle, dictionaryList} = DictionaryStore;
    const [filterModal, setFilterModal] = useState(false);
    const {suppliers, setSuppliersFilter} = SuppliersStore;
    const {businessUnits, setBusinessUnitFilter} = BusinessUnitsStore;
    const {products, setProductsFilter} = ProductsStore;
    const {users, setUsersFilter} = UsersStore;

    useEffect(() => {
        destroyDictionary()
        const controller = new AbortController();
        void initBillsOperations(controller.signal);
        void dictionaryListReq('PURCHASE_BILLS_OPERATIONS', 'TABLE HEADERS', controller.signal);
        setSuppliersFilter({page: 1, page_limit: 100000, technical: true});
        setBusinessUnitFilter({page: 1, page_limit: 100000});
        setProductsFilter({page: 1, page_limit: 100000});
        setUsersFilter({page: 1, page_limit: 10000});

        return () => {
            return controller.abort();
        }

    }, [initBillsOperations, dictionaryListReq, setSuppliersFilter, setBusinessUnitFilter, setProductsFilter, setUsersFilter]);

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setOperationsFilters({page: index + 1});
            return;
        }
        setOperationsFilters({[objectKey]: options[selectedIndex].value});
    };

    const prevPage = () => {
        if (operationsFilter.page! > 1) {
            setOperationsFilters({page: operationsFilter.page! - 1});
        }
    };

    const nextPage = () => {
        if (operationsFilter.page! < operationsFilter.pages!) {
            setOperationsFilters({page: operationsFilter.page! + 1});
        }
    };

    const pageLimits = [25, 50, 100, 250, 500];

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const usersOptions = users?.map(
        ({id, full_name}) => ({label: full_name, value: id})
    )

    const productsOptions = products?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const suppliersOptions = suppliers?.map(
        ({id, name}) => ({label: name, value: id})
    )

    const filterInputs = [
        {
            fieldName: 'user_id',
            placeholder: 'Все сотрудники',
            type: 'select',
            options: usersOptions,
        },
        {
            fieldName: 'business_unit_id',
            placeholder: 'Все подразделения',
            type: 'select',
            options: businessUnitsOptions,
        },
        {
            fieldName: 'product_id',
            placeholder: 'Все продукты',
            type: 'select',
            options: productsOptions,
        },
        {
            fieldName: 'supplier_id',
            placeholder: 'Все поставщики',
            type: 'select',
            options: suppliersOptions,
        },
    ];

    const handleCloseFilterModal = () => {
        setFilterModal(false)
    }

    return (
        <Page isNavOpen={isNavOpen} fadeIn vertical between={20}>
            <Headline color={primaryColor}>{getPageTitle('PURCHASE_BILLS_OPERATIONS')}</Headline>
            <TableHeader>
                <Filters hAlign="space-between" wrapped between={10}>
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={false}
                        filters={operationsFilter}
                        handleFilterChange={handleFilterChange}
                    />
                </Filters>
                <FilterButton
                    id={'bills-operations-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
            </TableHeader>
            <TableDesktopView data={operations} headers={dictionaryList} loading={loading}/>
            <TableMobileView data={operations} headers={dictionaryList} loading={loading}/>
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={operationsFilter.pages}
                currentPage={operationsFilter.page!}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {filterModal && (
                <ModalView
                    title={'Фильтры'}
                    onClose={handleCloseFilterModal}
                >
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={true}
                        filters={operationsFilter}
                        handleFilterChange={handleFilterChange}
                    />
                </ModalView>
            )}
        </Page>
    );
});

export default PurchaseBillsOperationsView;