import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import ClientsCreateEditStore from './clients.create.edit.store';
import ClientsStore from '../clients.store';
import {useEffect} from "react";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import {ClientsModel} from "../clients.model";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

interface ClientsCreateEditViewProps {
    initialValue: ClientsModel | null;
    setModal: (isOpen: boolean) => void;
}

const ClientsCreateEditView = observer(({initialValue, setModal}: ClientsCreateEditViewProps) => {
    const {
        form,
        setField,
        init,
        createOrEditClient,
        isLoading,
        destroy,
        clientTypeIDOptions
    } = ClientsCreateEditStore;
    const {
        full_name,
        pseudonym,
        phone,
        email,
        current_address,
        company,
        type_id,
        inn,
        balance,
    } = form;
    const {clientsReq} = ClientsStore;
    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        dictionaryListReq('CLIENTS', 'MODAL', controller.signal);
        init(initialValue);

        return () => {
            destroy();
            controller.abort();
        }
    }, [destroyModalDictionary, dictionaryListReq, destroy, init, initialValue]);

    const inputs = [
        {field: full_name, fieldName: 'full_name', placeholder: getModalPlaceholders('full_name'), type: 'text', required: true},
        {field: pseudonym, fieldName: 'pseudonym', placeholder: getModalPlaceholders('pseudonym'), type: 'text', required: false},
        {field: inn, fieldName: 'inn', placeholder: getModalPlaceholders('inn'), type: 'text', required: false},
        {field: type_id, fieldName: 'type_id', placeholder: getModalPlaceholders('type_id'), type: 'select', required: true, options: clientTypeIDOptions},
        {field: phone, fieldName: 'phone', placeholder: getModalPlaceholders('phone'), type: 'text', required: false},
        {field: email, fieldName: 'email', placeholder: getModalPlaceholders('email'), type: 'text', required: false},
        {field: current_address, fieldName: 'current_address', placeholder: getModalPlaceholders('current_address'), type: 'text', required: false},
        {field: company, fieldName: 'company', placeholder: getModalPlaceholders('company'), type: 'text', required: false},
        {field: balance, fieldName: 'balance', placeholder: 'Начальный баланс', type: 'number', required: false},
    ];

    const handleCreateEditClient = async () => {
        const res = await createOrEditClient(!!initialValue);
        if (res) {
            setModal(false);
            await clientsReq(false);
        }
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleCreateEditClient}
                    disabled={isValid([inputs])}
                >{isLoading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    );
});

export default ClientsCreateEditView;
