import { Route, Routes as BrowserRoutes } from 'react-router-dom';
import { observer } from 'mobx-react';
import AppStore from './app.store';
import RolesView from './pages/roles/roles.view';
import BusinessUnitsView from './pages/business_units/business_units.view';
import ProductsView from './pages/products/products.view';
import TransactionsView from './pages/transactions/transactions.view';
import MainView from './pages/main/main.view';
import UsersView from './pages/users/users.view';
import LoginView from './pages/login/login.view';
import { PermissionGroup } from './utils/permission';
import { hasPermission } from './utils/hasPermission';
import BusinessServiceView from './pages/business_services/business_service.view';
import { Stock } from './pages/stock/stock.view';
import { ProductsTransactionsView } from './pages/products_transactions/products_transactions.view';
import ClientsView from './pages/clients/clients.view';
import BrandsView from './pages/brands/brands.view';
import ProductCategoriesView from './pages/product_categories/product_categories.view';
import SuppliersView from './pages/suppliers/suppliers.view';
import { Transfers } from './pages/transfer_of_goods/transfer.view';
import StatusesView from "./pages/statuses/statuses.view";
import AllowancesView from "./pages/payrolls/allowances/allowances.view";
import DeductionsView from "./pages/payrolls/deductions/deductions.view";
import EmployeeAllowancesView from "./pages/payrolls/employee.allowances/employee.allowances.view";
import EmployeeDeductionsView from "./pages/payrolls/employee.deductions/employee.deductions.view";
import PayrollsView from "./pages/payrolls/payrolls/payrolls.view";
import ExpenseCategoriesView from "./pages/expenses/expense.categories/expense.categories.view";
import ExpensesView from "./pages/expenses/expenses/expenses.view";
import ExpenseOperationsView from "./pages/expenses/expense.operations/expense.operations.view";
import SalesReportView from "./pages/reports/sales.report/sales.report.view";
import FinReportsView from "./pages/reports/fin.reports/fin.reports.view";
import GoodsMovementView from "./pages/goods_movement/goods_movement.view";
import ShipmentsView from "./pages/shipments/shipments.view";
import PurchaseBillsView from "./pages/purchase.bills/purchase.bills.view";
import PurchaseBillsOperationsView from "./pages/purchase.bills.operations/purchase.bills.operations.view";
import ClientsDebtsView from "./pages/clients.debts/clients.debts.view";
import ClientsDebtsOperationsView from "./pages/clients.debts.operations/clients.debts.operations.view";

const Routes = observer(() => {
  const { permissions } = AppStore;

  if (!permissions) {
    return (
      <BrowserRoutes>
        <Route path="/login" element={<LoginView />} />
      </BrowserRoutes>
    );
  }

  const routes = [
    {
      path: '/login',
      component: <LoginView />,
    },
    {
      path: '/transactions',
      component: <TransactionsView />,
      hidden: !hasPermission(permissions, PermissionGroup.TRANSACTIONS, 'READ'),
    },
    {
      path: '/business_stock/shipments',
      component: <ShipmentsView />,
      hidden: !hasPermission(permissions, PermissionGroup.PRODUCTS, 'READ'),
    },
    {
      path: '/business_units',
      component: <BusinessUnitsView />,
      hidden: !hasPermission(
        permissions,
        PermissionGroup.BUSINESS_UNITS,
        'READ'
      ),
    },
    {
      path: '/business_stock/list',
      component: <Stock />,
      hidden: !hasPermission(
        permissions,
        PermissionGroup.BUSINESS_STOCKS,
        'READ'
      ),
    },
    {
      path: '/business_stock/transfers',
      component: <Transfers />,
      hidden: !hasPermission(permissions, PermissionGroup.TRANSFERS, 'READ'),
    },
    {
      path: '/products/list',
      component: <ProductsView />,
      hidden: !hasPermission(permissions, PermissionGroup.PRODUCTS, 'READ'),
    },
    {
      path: '/products/brands',
      component: <BrandsView />,
      hidden: !hasPermission(
        permissions,
        PermissionGroup.PRODUCT_BRANDS,
        'READ'
      ),
    },
    {
      path: '/products/categories',
      component: <ProductCategoriesView />,
      hidden: !hasPermission(
        permissions,
        PermissionGroup.PRODUCT_CATEGORIES,
        'READ'
      ),
    },
    {
      path: '/business_stock/movements',
      component: <GoodsMovementView />,
      hidden: !hasPermission(permissions, PermissionGroup.PRODUCTS, 'READ'),
    },
    {
      path: '/business_stock/bills',
      component: <PurchaseBillsView />,
      hidden: !hasPermission(permissions, PermissionGroup.BUSINESS_STOCKS, 'READ'),
    },
    {
      path: '/business_stock/bills_operations',
      component: <PurchaseBillsOperationsView/>,
      hidden: !hasPermission(permissions, PermissionGroup.BUSINESS_STOCKS, 'READ'),
    },
    {
      path: '/cashier/services',
      component: <BusinessServiceView />,
      hidden: !hasPermission(
        permissions,
        PermissionGroup.BUSINESS_SERVICES,
        'READ'
      ),
    },
    {
      path: '/cashier/goods',
      component: <ProductsTransactionsView />,
    },
    {
      path: '/roles',
      component: <RolesView />,
      hidden: !hasPermission(permissions, PermissionGroup.ROLES, 'READ'),
    },
    {
      path: '/users',
      component: <UsersView />,
      hidden: !hasPermission(permissions, PermissionGroup.USERS, 'READ'),
    },
    {
      path: '/clients/list',
      component: <ClientsView />,
      hidden: !hasPermission(permissions, PermissionGroup.CLIENTS, 'READ'),
    },
    {
      path: '/clients/debts',
      component: <ClientsDebtsView />,
      hidden: !hasPermission(permissions, PermissionGroup.CLIENTS, 'READ'),
    },
    {
      path: '/clients/payments',
      component: <ClientsDebtsOperationsView />,
      hidden: !hasPermission(permissions, PermissionGroup.CLIENTS, 'READ'),
    },
    {
      path: '/reports/sales',
      component: <SalesReportView />,
      hidden: !hasPermission(permissions, PermissionGroup.REPORTS, 'READ'),
    },
    {
      path: '/reports/financial',
      component: <FinReportsView />,
      hidden: !hasPermission(permissions, PermissionGroup.REPORTS, 'READ'),
    },
    {
      path: '/',
      component: <MainView />,
    },
    {
      path: '/products-transactions',
      component: <ProductsTransactionsView />,
    },
    {
      path: '/products/suppliers',
      component: <SuppliersView />,
      hidden: !hasPermission(permissions, PermissionGroup.SUPPLIERS, 'READ'),
    },
    {
      path: '/settings/statuses',
      component: <StatusesView />,
      hidden: !hasPermission(permissions, PermissionGroup.STATUSES, 'READ'),
    },
    {
      path: '/payrolls/allowances',
      component: <AllowancesView />,
      hidden: !hasPermission(permissions, PermissionGroup.PAYROLLS, 'READ'),
    },
    {
      path: '/payrolls/deductions',
      component: <DeductionsView />,
      hidden: !hasPermission(permissions, PermissionGroup.PAYROLLS, 'READ'),
    },
    {
      path: '/payrolls/employee/allowances',
      component: <EmployeeAllowancesView />,
      hidden: !hasPermission(permissions, PermissionGroup.PAYROLLS, 'READ'),
    },
    {
      path: '/payrolls/employee/deductions',
      component: <EmployeeDeductionsView />,
      hidden: !hasPermission(permissions, PermissionGroup.PAYROLLS, 'READ'),
    },
    {
      path: '/payrolls/payments',
      component: <PayrollsView />,
      hidden: !hasPermission(permissions, PermissionGroup.PAYROLLS, 'READ'),
    },
    {
      path: '/expenses/list',
      component: <ExpensesView />,
      hidden: !hasPermission(permissions, PermissionGroup.EXPENSES, 'READ'),
    },
    {
      path: '/expenses/categories',
      component: <ExpenseCategoriesView />,
      hidden: !hasPermission(permissions, PermissionGroup.EXPENSES, 'READ'),
    },
    {
      path: '/expenses/operations',
      component: <ExpenseOperationsView />,
      hidden: !hasPermission(permissions, PermissionGroup.EXPENSES, 'READ'),
    },
  ];

  return (
    <BrowserRoutes>
      {routes
        .filter((route) => !route.hidden)
        .map((route, index) => (
          <Route key={index} path={route.path} element={route.component} />
        ))}
    </BrowserRoutes>
  );
});

export default Routes;
